import {computed, nextTick, reactive, ref} from "vue";
import {orderLabelsList} from '@/utils/api/rfid.js'
import message from "@utils/tool/message";
import {inStockList} from "@views/order/orderConfig";

export default function (goodsInfo) {
    const loading = ref(false)
    const tableData = ref([])
    const rfidInfo = reactive({
        labelId: '',
        rfid: "",
        serial: '',
        orderSn: goodsInfo.value.orderSn,
        productId: goodsInfo.value.productId,
        modelId: goodsInfo.value.modelId,
    })

    const getRfidList = () => {
        loading.value = true
        orderLabelsList({
            orderSn: goodsInfo.value.orderSn,
            productId: goodsInfo.value.productId,
            modelId: goodsInfo.value.modelId,
            orderType: goodsInfo.value.orderType || route.params.orderType || '',
            scattered: goodsInfo.value.scattered || false
        }).then(res => {
            if (res.code != 0) {
                message.error(res.msg)
                return
            }
            tableData.value = res.data?.list || []
            nextTick().then(() => {
                loading.value = false
            })
        })
    }
    const getTotalCount = computed(() => {
        if(goodsInfo.value.operateCount){
            return  goodsInfo.value.operateCount
        }
        if (goodsInfo.value.orderType === 4) {
            return goodsInfo.value.inCount || goodsInfo.value.realityCount || 0
        } else if (goodsInfo.value.orderType === 8) {
            return goodsInfo.value.outCount || goodsInfo.value.realityCount || 0
        } else {
            return goodsInfo.value.realityCount || 0
        }
    })

    const getTotalPrice = computed(() => {
        if(goodsInfo.value.operatePrice){
            return  goodsInfo.value.operatePrice
        }
        if (goodsInfo.value.orderType === 4) {
            return goodsInfo.value.inTotalPrice || goodsInfo.value.realityTotalPrice || 0
        } else if (goodsInfo.value.orderType === 8) {
            return goodsInfo.value.outTotalPrice || goodsInfo.value.realityTotalPrice || 0
        } else {
            return goodsInfo.value.realityTotalPrice || 0
        }
    })

    const showScattered = computed(() => {
        return tableData.value.findIndex(i => i.scatteredCount > 0) > -1 && !inStockList.includes(+goodsInfo.value.orderType)
    })

    return {
        rfidInfo,
        getRfidList,
        getTotalCount,
        getTotalPrice,
        tableData,
        showScattered,
        loading
    }
}