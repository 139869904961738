/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-30 17:58:45
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-10-10 11:22:10
 * @FilePath: /aurora-carton-web/src/utils/tool/order.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {reactive} from "vue";
import store from "@/store";

export function setOrderDetail(orderInfo) {
    //orderInfo存到vuex里面
    store.commit('PURCHASE_ORDER_INFO', orderInfo);
}

//获取orderDetailcheck
export function getOrderDetail() {
    const orderInfo = reactive(store.state.purchaseOrderInfo);
    return {
        orderInfo
    }
}