/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-09 10:53:45
 * @FilePath: /aurora-carton-web/src/views/order/uitl.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {reactive, ref} from 'vue';
import { orderConfig} from './orderConfig';
import { cloneDeep } from 'lodash';
export function getConfig(orderType){
  const titleList = ref([])
  const titleListForCheck = ref([])
  const titleListIn = ref([])
  const titleListForCheckIn = ref([])
  const addBtnText = ref('')
  const addSearchText = ref('')
  const addStockText = ref('')
  const orderText =ref('')
  const searchModal = reactive({
    typeName: '',
    placeholder: '',
  })
  const orderInfoText = ref({})
  const inColumnsTableConfig = ref('')
  let obj = orderConfig[orderType]
  addBtnText.value = obj.addBtnText
  orderText.value = obj.orderText
  addSearchText.value = obj.addSearchText
  addStockText.value = obj.addStockText
  titleList.value = cloneDeep(obj.columns)
  titleListForCheck.value = cloneDeep(obj.columns)
  titleListIn.value = cloneDeep(obj.inColumns)
  titleListForCheckIn.value = cloneDeep(obj.inColumns)
  searchModal.typeName = obj.searchModal.typeName
  searchModal.placeholder = obj.searchModal.placeholder
  orderInfoText.value = cloneDeep(obj.orderInfoText)
  inColumnsTableConfig.value = cloneDeep(obj.inColumnsTableConfig)
  return{
    titleList,
    titleListForCheck,
    addBtnText,
    searchModal,
    addSearchText,
    orderInfoText,
    titleListIn,
    titleListForCheckIn,
    inColumnsTableConfig,
    addStockText,
    orderText
  }
}
