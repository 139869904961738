import {reactive} from "vue";
import {reactiveAssign} from "@utils/tool";
import {productInfo} from "@/utils/api/product/product.js";
import message from "@utils/tool/message";

export default function () {
    const goodsInfo = reactive({
        orderSn: '',
        productId: '',
        modelId: "",
        productName: "",
        encode: [],
        unitName: "",
        modelName: "",
        checkPrice: "",
        brandName: "",
        checkCount: "",
        subName: "",
        price: "",
        img: ""
    })
    const showRfidFunc = (e, callBack) => {
        reactiveAssign(goodsInfo, e)
        getGoodsDetail(e, callBack)
    };
    const getGoodsDetail = (e, callBack) => {
        productInfo({
            productId: e.productId,
            scattered: e.scattered,
        }).then((res) => {
            if (res.code != 0) {
                message.error(res.msg);
                return;
            }
            Object.assign(goodsInfo, res.data, e)
            if (typeof callBack === 'function') {
                callBack(res.data)
            }
        })
    }
    return {
        goodsInfo,
        showRfidFunc,
        getGoodsDetail
    }
}