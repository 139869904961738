/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-17 10:58:08
 * @FilePath: /aurora-carton-web/src/utils/api/inbound/transfer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../../http'

//调拨单列表接口
export function transferOrderList(data){
  return request({
    url:"/carton/transfer/order/list",
    method:'post',
    data
  })
}

//调拨单详情接口
export function transferOrderInfo(data){
  return request({
    url: "/carton/transfer/order/detail",
    method:'post',
    data
  })
}

//调拨单商品列表
export function transferOrderProduct(data){
  return request({
    url: "/carton/transfer/order/product/list",
    method:'post',
    data
  })
}

//调拨审批接口
export function transferOrderExamine(data){
  return request({
    url:"/carton/transfer/order/examine",
    method:'post',
    data
  })
}

//新增调拨
export function transferOrderAdd(data){
  return request({
    url:"/carton/transfer/order/add",
    method:'post',
    data
  })
}

//编辑调拨
export function transferOrderEdit(data){
  return request({
    url:"/carton/transfer/order/edit",
    method:'post',
    data
  })
}

//删除订单
export function orderDel(data){
  return request({
    url:"/carton/transfer/order/del",
    method:'post',
    data
  })
}