import {reactive, ref} from 'vue';
import {useStore} from "vuex";
import {reactiveAssign, resetGoodsList} from "@utils/tool";
import {orderGoodsList, orderListDeatil} from '@/utils/api/order.js'
import filter from '@/utils/filter.js'
import message from "@utils/tool/message";

export default function () {
    const store = useStore()
    const tableData = ref([])
    const orderInfo = reactive({
        orderSn: '',
        supplierName: "",
        productName: [],
        createTime: "",
        createUserName: "",
        remark: "",
        orderStatus: 0,
    });

    const setOrderInfo = () => {
        reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
    }
    const getGoodsListDetFunc = (orderType, callBack) => {
        orderListDeatil({
            orderSn: orderInfo.orderSn,
            orderType: orderType,
        }).then((res) => {
            if (res.code !== 0) {
                message.error(res.msg)
                return
            }
            if (typeof callBack === 'function') {
                callBack(res.data)
            }
            reactiveAssign(orderInfo, res.data);
        })
    }
    const getInStockGoodsList = (orderType, callBack) => {
        orderGoodsList({
            orderSn: orderInfo.orderSn,
            orderType: orderType,
            orderStatus: 1
        }).then((res) => {
            if (res.code !== 0) {
                message.error(res.msg)
                return
            }
            let list = res.data?.list || []
            tableData.value = resetGoodsList(list, (item) => {
                item.price = filter.toYuan(item.price, '元')
                item.totalPrice = filter.toYuan(item.totalPrice, '元')
                item.realityTotalPrice = filter.toYuan(item.realityTotalPrice, '元')
                item.orderType = orderType
            });
            if (typeof callBack === 'function') {
                callBack(tableData.value)
            }
        })
    }
    return {
        orderInfo,
        setOrderInfo,
        getGoodsListDetFunc,
        getInStockGoodsList,
        tableData
    }
}