<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
        v-bind="$attrs"
        title="调拨订单"
        width="1400"
        class="goodInfo"
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openDetails"
        @close="close"
    >
      <div class="zh_content">
        <div class="info">
          <div class="zh_title">基本信息</div>
          <div class="zh_introduce">
            <div class="item">
              <div class="text">订单编号：</div>
              <div class="desc">{{ orderInfo.orderSn }}</div>
            </div>
            <div class="item">
              <div class="text">调出仓库：</div>
              <div class="desc">{{ orderInfo.outStockName }}</div>
            </div>
            <div class="item">
              <div class="text">收货仓库：</div>
              <div class="desc">{{ orderInfo.inStockName }}</div>
            </div>
          </div>
          <div class="zh_title mt32">消息</div>
          <div class="zh-message mt_16">
            <el-carousel height="48px" direction="vertical" :autoplay="true" indicator-position='none' :pause-on-hover='false'>
              <el-carousel-item v-for="item in chatList" :key="item.time">
                <div class="mes-box">
                  <div class="time" >{{$filters.timeFormat(item.time,1)}}</div>
                  <div class="mes">
                    <img :src="item.avatar"  class="ava" />
                    <div class="nickName">{{item.nickName}}：</div>
                    <div class="mes-text">{{item.text}}</div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <img class="send-mes cur_p" @click="handleChat" src="../../../../assets/img/inbound/mes.png" />
          </div>
          <div class="zh_title mt32">产品信息</div>
          <div class="zh_introduce">
            <div class="item">
              <div class="text">{{ orderType == 4 ? '入库' : '出库' }}合计数量：</div>
              <div class="desc">{{ goodsNum.count }}</div>
            </div>
            <div class="item">
              <div class="text">{{ orderType == 4 ? '入库' : '出库' }}合计金额(元)：</div>
              <div class="desc">{{ goodsNum.totalPrice }}</div>
            </div>
          </div>
          <div class="tabList">
            <el-table :data="tableData"
                      style="width: 100%"
                      height="100%"
                      class="zh-no-table-border"
                      :row-class-name="tableRowClassName"
                      :cell-style="{ border: 'none' }"
                      scrollbar-always-on
                      :header-row-style="{ border: 'none' }">
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              />
              <el-table-column
                  width="254"
                  align="center"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #header>
                  <div>产品名称</div>
                </template>
                <template #default="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column
                  width="100"
                  align="center"
                  label="简称"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.subName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  width="130"
                  align="center"
                  class-name="table_text"
                  label="产品品牌"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.brandName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  align="center"
                  class-name="table_text2"
                  show-overflow-tooltip
                  label="产品单位"
              >
                <template #default="scope">
                  {{ scope.row.unitName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  :width="orderType == 4?'150':'200'"
                  align="center"
                  class-name="table_text2"
                  label="规格/型号"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.modelName || "--" }}
                </template>
              </el-table-column>
              <!-- <el-table-column width="100" align="center">
                <template #header>
                  <div>单价(元)</div>
                </template>
                <template #default="scope">
                  <div class="table_text2">
                    {{ "￥" + scope.row.price }}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column width="200" align="center">
                <template #header>
                  <div>计划数量</div>
                </template>
                <template #default="scope">
                  <div class="table_text2">
                    {{ scope.row.count }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="200" align="center" label="出库数量/金额（元）">
                <template #default="scope">
                  <div class="table_dp_item">
                    <span class="table_text2">{{ scope.row.outCount || 0 }}</span> <span class="unit">/</span><span
                      class="table_text2"> {{ '￥' + scope.row.outTotalPrice }}</span><span class="contBtn"
                                                                                           @click="showRfid(scope.row,8)"
                                                                                           v-if="scope.row.outCount > 0 && !noMore">查看明细</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="200" align="center" label="入库数量/金额（元）" v-if='orderType == 4 '>
                <template #default="scope">
                  <div class="table_dp_item">
                    <span class="table_text2">{{ scope.row.inCount || 0 }}</span> <span class="unit">/</span><span
                      class="table_text2"> {{ '￥' + scope.row.inTotalPrice }}</span><span class="contBtn"
                                                                                          @click="showRfid(scope.row,4)"
                                                                                          v-if="scope.row.inCount > 0 && !noMore">查看明细</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
    <RfidList v-model="rfidShow" :oderStauts='chooseOrderStauts' v-model:goodsInfo="goodsInfo"></RfidList>
    <Chat v-model='chatShow' :orderInfo="orderInfo" isOver></Chat>
  </div>
</template>
<script setup>
import Chat from './chat.vue';
import {tableRowClassName} from '@/utils/tool.js';
import {computed, getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {productInfo} from '@/utils/api/product/product.js'
import {useStore} from "vuex";
import {transferOrderInfo, transferOrderProduct} from '@/utils/api/inbound/transfer.js';
import {orderMsg} from "@/utils/api/chat.js"
import {useRoute} from "vue-router";

const props = defineProps({
  orderNum: {
    type: String,
    default: '',
  },
  noMore: {
    type: Boolean,
    default: false,
  },
});
const { orderNum ,noMore} = toRefs(props);
const { proxy } = getCurrentInstance();
const emit = defineEmits(['close'])
const chatShow = ref(false)
const chatList = ref([])
const addShow = ref(false)
const route = useRoute();
const store = useStore();
const orderType = ref('');
//获取配置文件
const orderInfo = reactive({
  orderSn:'',
  supplierName:"",
  productName:[],
  createTime:"",
  createUserName:"",
  remark:"",
  orderStatus:0,
});
const goodsInfo = reactive({
  orderSn:'',
  productId:'',
  modelId:"",
  productName:"",
  encode:[],
  unitName:"",
  modelName:"",
  checkPrice:"",
  brandName:"",
  checkCount:"",
  subName:"",
  price:"",
  img:""
})
const chooseOrderStauts = ref('')
//打开标签明细
const showRfid =async (e,type) => {
  reactiveAssign(goodsInfo,e);
  goodsInfo.orderType = orderType.value;
  if(orderType.value == 8){
    goodsInfo.orderSn = orderInfo.orderSn;
    chooseOrderStauts.value = type
    await getGoodsDetail(e)
  }else{
    if(type == 8){
      goodsInfo.orderSn = orderInfo.thirdName;
    }else{
      goodsInfo.orderSn = orderInfo.orderSn;
    }
    chooseOrderStauts.value = type
    await getGoodsDetail(e)
  }
};

//获取聊天列表
const getChatList = ()=>{
  orderMsg({
    orderSn: orderInfo.orderSn,
    orderType: orderType.value,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    chatList.value = res.data.list || [];
  });
}

// 获取产品详情
const getGoodsDetail = (e)=>{
  productInfo({
    productId:e.productId
  }).then((res)=>{
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(goodsInfo,res.data)
    rfidShow.value = true;
  })
}

const handleChat = () => {
  chatShow.value = true;
};

const goodsNum = computed(() => {
  //8 出库，4入库
  let count = 0;
  let totalPrice = 0
  tableData.value.forEach((item) => {
    count += Number(orderType.value == 4 ?item.inCount : item.outCount) || 0;
    totalPrice +=  Number(orderType.value == 4 ?item.inTotalPrice : item.outTotalPrice) || 0
  });
  return{
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice:totalPrice.toFixed(2)
  }
});

const tableData = ref([]);

const rfidShow = ref(false);

const openDetails = () => {
  reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
  orderType.value = orderInfo.orderType || route.params.orderType  || 0
  transferOrderInfo({
    orderSn:orderInfo.orderSn,
    orderType:orderType.value,
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    reactiveAssign(orderInfo, res.data);
     proxy.$store.commit("PURCHASE_ORDER_INFO", res.data);
    getGoodsList();
    getChatList()
  })
};
//获取产品列表
const getGoodsList = ()=>{
  transferOrderProduct({
    orderSn:orderInfo.orderSn,
    orderStatus:1,
     orderType:orderType.value,
  }).then((res)=>{
    if(res.code !=0){
      proxy.$message.error(res.msg)
      return
    }
    if(res.data?.list?.length > 0){
      res.data.list.forEach((item)=>{
        item.price = proxy.$filters.toYuan(item.price,'元')
        item.outTotalPrice = proxy.$filters.toYuan(item.outTotalPrice,'元')
        item.inTotalPrice = proxy.$filters.toYuan(item.inTotalPrice,'元')
      })
    }
    tableData.value =res.data.list || []
    if(orderInfo.orderStatus == 2){
      tableData.value.push({})
    }
  })
}

const close = ()=>{
  proxy.$emit('close')
  proxy.$store.commit("PURCHASE_ORDER_INFO",{});
  tableData.value = []
}
</script>

<script>
import RfidList from "@/components/rfidList/indexByOrder.vue";
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { reactiveAssign } from "@/utils/tool.js";
import { ArrowDown } from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
export default {
  components: {
    ArrowDown,
    RfidList,
    SelectByAbcModal,
    SelectGoodsFromList
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
      .zh-message{
    height: 48px;
        line-height: 52px;
    background-color: #F7FAFC;
    border-radius: 8px;
    position: relative;
    .mes-box{
      display: flex;
      align-items: center;
      padding: 0 50px 0 22px;
      box-sizing: border-box;
      font-size: 14px;
      flex-wrap: nowrap;
      .time{
        color: #AAAAB2;
        margin-right: 20px;
      }
      .mes{
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        flex: 1;//在可用的空间内100%填充
        overflow: hidden;
        text-overflow: ellipsis;
        //规定段落中的文本不进行换行
        white-space: nowrap;
        .ava{
          width: 24px;
          height: 24px;
          border-radius: 8px;
          margin-right: 6px;
          position: relative;
          top: -2px;
        }
        .nickName{
          color: #737480;
        }
        .mes-text{
          color: #161829;
           overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .send-mes{
        width: 22px;
        height: 20px;
        overflow: hidden;
        position: absolute;
        top: 14px;
        right: 16px;
      }
   }
    .tabList {
      height: 480px;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        line-height: 1;
        cursor: pointer;
        margin-left: 8px;
        position: relative;
        top: -1px;
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;
      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }
      .remark_text{
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }
      .input {
        margin-top: 16px;
      }
      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;
        .info_btn{
          width: 112px;
        }
        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;
         :deep(.el-input){
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
