<template>
  <div class="label-desc" v-bind="$attrs">
    <more-text title="标签备注" :text="labelInfo.labelDesc || '无'"></more-text>
<!--      <div class="label-desc-title zh-key">：</div>-->
<!--        <div class="label-desc-content">{{ labelInfo.labelDesc }}</div>-->
      <img class="label-desc-update cur_p" src="@/assets/img/index/updateImg.png" alt="" @click="openDia" />
      <img class="label-desc-del cur_p" v-if="labelInfo.labelDesc" src="@/assets/img/index/del.png" alt="" @click="delLabelDesc" />
  </div>
    <el-dialog
        title="编辑"
        v-model="showModal"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        destroy-on-close
        align-center
        width="480"
        class="rfid-dialog"
        @open="openDia"
    >
    <el-form
    style="padding: 0 16px;"
    class="form"
     label-position="top">
      <el-form-item label="标签备注" class="form-item" style="margin-bottom: 0;">
        <el-input
            v-model.lazy="labelDesc"
            :placeholder="placeholder.labelDesc"
            v-elInput
            :maxlength="labelRemarkMaxNum"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="closeDia">取 消</el-button>
      <el-button type="primary" :disabled="!canSubmit" @click="submit">保存</el-button>
    </template>
    </el-dialog>
</template>

<script setup>
import { toRefs  } from 'vue';
import useLabelDesc from "@utils/hooks/rfid/useLabelDesc";
import useModal from "@utils/hooks/useModal";
import placeholder from "@/utils/config/placerholder.js";
import { labelRemarkMaxNum } from "@/utils/config/ruleConfig.js";
import MoreText from "@components/moreText/index.vue";

const { labelDesc, editRemark,deleteRemark ,canSubmit ,resetLabelDesc } = useLabelDesc()
const props = defineProps({
    labelInfo:{
      type: Object,
      default: () => ({})
    }
  }
)
const emit = defineEmits(["change"]);
const {showModal, handleOpenModal, handleCloseModal} = useModal()
const { labelInfo } = toRefs(props);
const delLabelDesc = async () => {
  const result = await deleteRemark(labelInfo.value)
  if(result){
    emit('change','')
  }
}
const closeDia = ()=>{
  handleCloseModal()
}
const submit = async () => {
  const result = await editRemark(labelInfo.value, labelDesc.value)
  if(result){
    handleCloseModal()
    emit('change',labelDesc.value)
  }
}
const openDia = ()=>{
  resetLabelDesc(labelInfo.value.labelDesc)
  handleOpenModal()
}

</script>

<style lang="scss" scoped>
.label-desc{
  display: flex;
  align-items: center;
  width:100%;
  >div{
    margin-right: 10px;
  }
  >img{
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .label-desc-update{
    margin-right: 12px;
  }
  .label-desc-title{
    color: #737480;
  }
}
.rfid-dialog{
  .form{
    .form-item{
      :deep(.el-form-item__label){
        color:#737480;
      }
    }
  }
}
</style>