/*
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-16 17:05:46
 * @FilePath: /aurora-carton-web/src/views/inStock/InventoryRecord/orderConfig.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const tabTitle ={
   //盘点列表
   columns:[
    {
      title:"盘点单号",
      fieldKey:"inventoryNo",
      isShow:true,
      className:'table_text'
    },
    {
      title:"盘点仓库",
      fieldKey:"stockName",
      isShow:true
    },
    {
      title:"状态",
      fieldKey:"statusDesc",
      isShow:true,
      filters:
      [
        { text: '待盘点', value: '1' },
        { text: '盘点中', value: '2' },
        { text: '已取消', value: '3' },
          {text: '已失效', value: '5'}
      ]
    },
    {
      title:"创建人",
      fieldKey:"createUserName",
      isShow:true,
    },
    {
      title:"创建时间",
      fieldKey:"createTime",
      isShow:true,
    },
  ],
  //盘点记录
  inColumns:[
    {
      title:"盘点单号",
      fieldKey:"inventoryNo",
      isShow:true,
        width: 200,
      className:'table_text'
    },
    {
      title:"盘存仓库",
      fieldKey:"stockName",
      isShow:true
    },
    {
      title:"创建人",
      fieldKey:"createUserName",
      isShow:true
    },
    {
      title:"创建时间",
      fieldKey:"createTime",
      isShow:true,
      sortable:true
    },
    {
      title:"完成人",
        fieldKey: "stockUserName",
      isShow:true,
    },
    {
      title:"完成时间",
        fieldKey: "stockTime",
      isShow:true,
    },
    {
      title:"备注",
      fieldKey:"remark",
      isShow:true,
    },
  ],
}

export const orderConfig = {
    //待盘点
    wait: 1,
    //盘点中
    ing: 2,
    //已取消
    cancel: 3,
    //已完成
    finish: 4,
    //已失效
    invalid: 5,
}

export const inventoryType = {
    //0按产品 1按供应商 2按分类 3按品牌 4仓库全盘
    product: 0,
    supplier: 1,
    category: 2,
    brand: 3,
    allStock: 4,
}

//盘点单出入库orderType
export const inventoryOrderType = {
    //盘点单出库
    out: 9,
    //盘点单入库
    in: 5,
}

export const getInventoryTypeName = (type) => {
    type = Number(type)
    switch (type) {
        case 0:
            return '按产品盘点'
        case 1:
            return '按供应商盘点'
        case 2:
            return '按产品分类盘点'
        case 3:
            return '按产品品牌盘点'
        case 4:
            return '仓库全盘'
        default:
            return ''
    }
}