<template>
  <!-- 聊天界面 -->
  <el-dialog
    v-bind="$attrs"
    title="消息"
    width="920"
    class="chat chat-dialog"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @open="openChat"
    @close="closeChat"
  >
    <div class="zh-chat">
      <div class="chat-box" ref="boxContent">
        <template v-for="(item,index) in mesList" :key="item.time">
           <div class="single animate__animated animate__fadeIn" v-if='item.orderType != orderType'>
           <div class="time" v-if='!mesList[index - 1] || ((mesList[index - 1] && item.time- mesList[index - 1].time ) > 300000 )'>{{$filters.timeFormat(item.time,1)}}</div>
          <div class="mes">
            <div class="mes-img">
              <img
               :src="item.avatar"
                class="ava"
              />
            </div>
            <div class="mes-text">
              <div class="mes-name">{{item.nickName}}</div>
              <div class="mes-content">
               {{item.text}}
              </div>
            </div>
          </div>
        </div>
        <div class="single animate__animated animate__fadeIn" v-else>
           <div class="time" v-if='!mesList[index - 1] || ((mesList[index - 1] && item.time- mesList[index - 1].time ) > 300000 )'>{{$filters.timeFormat(item.time,1)}}</div>
          <div class="mes mine">
            <div class="mes-text">
              <div class="mes-name">{{item.nickName}}</div>
              <div class="mes-content">
               {{item.text}}
              </div>
            </div>
            <div class="mes-img">
              <img
               :src="item.avatar"
                class="ava"
              />
            </div>
          </div>
        </div>
        </template>
      </div>
      <div class="mes-box" v-if="!isOver">
          <el-input
              v-elInput
            v-model="remark"
            placeholder="输入信息"
            :class="[noMes?'err':'','textareaInput']"
            input-style="border: none;box-shadow:none"
            maxlength="100"
            @keydown.enter="handleSendMsg"
          />
      </div>
      <div class="btn-box" v-if="!isOver">
        <el-button class="btn1 info_btn send-btn" type="primary" @click='handleSendMsg'>发送</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import {useStore} from "vuex";
import {getCurrentInstance, ref, toRefs,} from "vue";
import {getOrderType} from '../uitl';
import {orderMsg, sendMsg} from "@/utils/api/chat.js"

const props = defineProps({
  isOver:{
    type:Boolean,
    default:false
  },
  orderInfo:{
    type:Object,
    default:{}
  }
})
const boxContent = ref(null)
const {isOver,orderInfo} = toRefs(props)
const {proxy}   = getCurrentInstance();
const store = useStore();
const orderType = getOrderType()
let timer = null
const mesList = ref([]);
const remark = ref('')
const noMes = ref(false)
let chatTimer = null
//获取聊天列表
const getChatList = ()=>{
  if(chatTimer) clearTimeout(chatTimer)
  orderMsg({
    orderSn:orderInfo.value.orderSn,
    orderType: orderType.value,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data?.list || []
    if(list.length > mesList.value.length){
      mesList.value = list;
      setTimeout(()=>{
        boxContent.value.scrollTo({
          top: boxContent.value.scrollHeight - boxContent.value.clientHeight,
          behavior: "smooth",
        })
      },100)
    }
    chatTimer = setTimeout(()=>{
      getChatList()
    },1000)
  });
}
const handleSendMsg = ()=>{
  if(!remark.value){
    if(timer) clearTimeout(timer)
    noMes.value = true
    timer = setTimeout(()=>{noMes.value = false},2000)
    return
  }
  sendMsg({
    orderSn:orderInfo.value.orderSn,
    text:remark.value,
    orderType: orderType.value,
  }).then((res)=>{
    if(res.code == 0){
      remark.value = ''
      getChatList()
    }else{
      proxy.$message.error(res.msg)
    }
  })
}
const openChat = ()=>{
  remark.value = ''
  noMes.value = false
  getChatList()
  setTimeout(()=>{
    boxContent.value.scrollTo({
      top: boxContent.value.scrollHeight - boxContent.value.clientHeight,
      behavior: "smooth",
    })
  },100)
}


const closeChat = ()=>{
  if(chatTimer) clearTimeout(chatTimer)
}

</script>

<style lang="scss" scoped>
.zh-chat {
  height: 824px;
  .chat-box {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: 694px;
    @include overFlow();
    padding: 32px;
    box-sizing: border-box;
    background-color: #f7f9fc;
    .single {
      margin-bottom: 42px;
      .time {
        text-align: center;
        color: #aaaab2;
        font-size: 12px;
      }
      .mes {
        display: flex;
        margin-top: 32px;
        &.mine {
          justify-content: flex-end;
          .mes-img {
            margin-left: 8px;
          }
          .mes-text {
            .mes-name {
              text-align: right;
            }
            .mes-content {
              background-color: #3859ff;
              color: #fff;
            }
          }
        }
        .mes-img {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          overflow: hidden;
          margin-right: 8px;
          .ava {
            width: 100%;
            height: 100%;
          }
        }
        .mes-text {
          .mes-name {
            color: #737480;
            font-size: 12px;
          }
          .mes-content {
            margin-top: 8px;
            padding: 10px 16px;
            background-color: #fff;
            border-radius: 8px;
            font-size: 14px;
            line-height: 1.4;
            max-width: 600px;
            color: #161829;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .mes-box {
    padding: 690px 16px 0;
      .textareaInput {
        margin-top: 16px;
        :deep(.el-input__wrapper){
          border-radius: 8px;
          background-color: #F2F4F7;
          border: none;
          box-shadow: none;
          height: 40px;
          line-height: 40px;
        }
        &.err{
          :deep(.el-input__wrapper){
          border: 1px solid red;
          position: relative;
          &::after{
            content: '请填写消息后再发送';
            display: block;
            position: absolute;
            bottom: -36px;
            left: 14px;
            font-size: 12px;
            color: red;
          }
         }
        }
      }
  }
  .btn-box{
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    padding-right: 16px;
    .send-btn{
      width: 160px;
      text-align: center;
    }
  }
}
.chat-dialog {
  position: relative;
}
</style>
