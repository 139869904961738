<template>
  <el-popover placement="bottom" :width="160" trigger="click">
    <template #reference>
      <el-button> <img src="@/assets/img/index/qrcode_btn.png" alt="qrcode" class="qrcode-img">查看订单二维码</el-button>
    </template>
    <div class="qrcode-box">
      <img :src="qrCodeUrl.value" alt="qrcode" class="qrcode"/>
      <div class="text">扫码可查看订单详情</div>
      <div class="text">（使用订单号也可查询）</div>
    </div>
  </el-popover>

</template>

<script setup>
import { computed } from "vue";
import { useQRCode } from "@vueuse/integrations/useQRCode";

const props = defineProps({
  qrCode: {
    type: String,
    default: "",
  },
});

const qrCodeUrl = computed(() => {
  console.log(props.qrCode);
  return useQRCode(props.qrCode || "");
});
</script>

<style lang="scss" scoped>
.qrcode-box{
  .qrcode{
    width: 130px;
    height: 130px;
    margin: 0 auto;
    display: block;
  }
  .text{
    font-size: 12px;
    text-align: center;
    line-height: 1;
  }
}
.qrcode-img{
  width: 16px;
  height: 16px;
  margin-right: 5px;
  vertical-align: middle;
}
</style>
