<template>
  <el-popover placement="bottom-start" :width="640" trigger="focus" :visible="showVisible"
              popper-class="inputPoper select-goods-poper">
    <template #reference>
      <el-input v-elInput class="name tablInput w240" v-model="formData.query" @blur="clearPop" @input="getGoodsList()" @keydown.enter="getGoodsList()" placeholder="输入产品名称/简称/简拼查询" ></el-input>
    </template>
    <el-table :data="goodsList" v-if='goodsList.length > 0'  class-name="select-goods-list" @row-click="handleClickRow" :span-method="objectSpanMethod"
    >
      <el-table-column width="120" align="center"  show-overflow-tooltip label="产品名称" >
        <template #default="scope">
         {{ scope.row.productName }}
        </template>
      </el-table-column>
      <el-table-column width="120" align="center"  show-overflow-tooltip label="产品品牌" >
        <template #default="scope">
          {{ scope.row.brandName }}
        </template>
      </el-table-column>
      <el-table-column width="120" prop="modelName" align="center"  show-overflow-tooltip label="规格/型号" />
      <el-table-column   align="center" label="条形码" >
        <template #default="scope">
          <div class="moreListContent" v-if="scope.row.encoded">
              <more-list :list='scope.row.encoded || []'></more-list>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column width="120" align="center"  show-overflow-tooltip label="产品单位" >
        <template #default="scope">
          <div class="cur_p"
               v-html="getUnitName(scope.row)">
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="noGoods" v-else>
      <div class="top cur_p" @click='leave()' v-if="thirdId === '' && stockId ===''">+添加</div>
      <div class="top cur_p" v-else></div>
      <div class="nogoodContent">
        <img src="@/assets/img/index/noGoods.png" alt="位元灵感" class="pic">
        <div class="noGoodsText">
          暂无产品信息
        </div>
        </div>
    </div>
  </el-popover>
</template>

<script setup>
import MoreList from '@/components/moreList/moreList.vue';
import {defineEmits, defineProps, getCurrentInstance, reactive, ref, toRefs} from 'vue'
import {packingProductSearch,} from "@/utils/api/product/packing.js";
import {orderGoodsSearch} from '@/utils/api/order.js'
import {reactiveAssign} from "@/utils/tool.js";
import {debounce} from "lodash";
import {useRoute, useRouter} from "vue-router";
import {ElMessageBox} from "element-plus";
import {getUnitName, uniqueProductList} from '@/utils/tool/orderTools.js'

const {proxy} = getCurrentInstance()
const router = useRouter()
const emit = defineEmits(['update:goods', 'change']);
const props = defineProps({
  goods: {
    type: Object,
    default: () => {},
  },
  propList:{
    type:Array,
    default:()=>[]
  },
  followTime:{
    type:Boolean,
    default:false
  },
  thirdId: [String, Number],
  stockId: [String, Number],
  orderType: [String, Number]
});
const {propList, goods, followTime, thirdId, stockId, orderType} = toRefs(props)
const showVisible = ref(false)
const route = useRoute();
const getOrderValue = ()=>{
  return  +orderType.value || +route.params.orderType || undefined
}
//商品列表
const goodsList = ref([])
const formData = reactive({
  query:"",
  productName:"",
  productId:"",
  brandId:"",
  brandName:"",
  unitId:"",
  unitName:"",
  modelName:"",
  modelId:"",
  subName:'',
  buyCount:"",
  count:"",
  price:"",
  butPrice:''
})
const getGoodsList = debounce(() => {
  if(!formData.query){
    return
  }
  let api ;
  if( stockId.value || thirdId.value){
    api = orderGoodsSearch
    }else{
    api = packingProductSearch
  }
  api({
    query: formData.query,
    followTime:followTime.value,
    thirdId:thirdId.value,
    stockId:stockId.value,
    orderType: getOrderValue()
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    let list = res.data.list || []
    list = list.map(item=>{
      if (item.scattered) {
          return [
            {...item,
              scatteredName:item.unitName,
              scattered:false,
              realLen:2
            },
            {
              ...item,
              scatteredCount:item.scatteredCount,
              scatteredName: item.scatteredName,
              realLen:0
            }
          ]
        }else{
          return {
            ...item,
            realLen:1
          }
        }
    }).flat()
    goodsList.value = uniqueProductList(list,propList.value)
    showVisible.value = true
  });
}, 200);
const handleClickRow = (item)=>{
  reactiveAssign(formData,item)
  reactiveAssign(formData,goods.value)
  if(formData.price){
   formData.price = ''
  }
  if(formData.totalPrice){
   formData.totalPrice = ''
  }
  if(formData.count){
   formData.count = ""
  }
  //判断采购1 ，销售7
  if(getOrderValue() === 1){
    formData.price =proxy.$filters.toYuan(item.buyPrice,'元')
  }
  if(getOrderValue() === 7){
    formData.price =proxy.$filters.toYuan(item.sellPrice,'元')
  }
  emit('update:goods',formData)
  emit('change',formData)
}
const leave = ()=>{
   ElMessageBox.confirm("页面未保存，是否离开当前页面", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
       customClass: "zh-message-box",
      }).then(() => {
        router.push({ path: "/product/productInformation",state:{add:1} });
      });
}
const clearPop = ()=>{
  //清空tooltips
  let list = document.getElementsByClassName("is-dark");
  if (list.length > 0) {
    list[list.length - 1].style.display = "none";
  }
  showVisible.value = false
}

const objectSpanMethod = ({row,
                          column,
                          rowIndex,
                          columnIndex})=>{
  const needColumList = [0,1,2,3]
    if (needColumList.indexOf(columnIndex) !== -1) {
      if (row.realLen) {
        return {
          rowspan: row.realLen,
          colspan: 1
        }
      } else {
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    }
}
</script>

<style lang="scss" scoped>

</style>
