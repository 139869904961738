<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
        v-model="showOrderInfo"
        title="盘点详情"
        width="1400"
        class="inStockInfoContent"
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openDetail"
        @close="close"
    >
      <div class="zh_content">
        <div class="info">
          <div class="zh_title">基本信息</div>
          <div class="zh_introduce status3 dom-height">
            <div class="item">
              <div class="text">盘点单号：</div>
              <div class="desc">{{ orderInfo.inventoryNo }}</div>
            </div>
            <div class="item">
              <div class="text">盘点仓库：</div>
              <div class="desc">{{ orderInfo.stockName }}</div>
            </div>
            <div class="item">
              <div class="text">创建人：</div>
              <div class="desc">{{ orderInfo.createUserName }}</div>
            </div>
            <div class="item">
              <div class="text">创建时间：</div>
              <div class="desc">{{ $filters.timeFormat(orderInfo.createTime, 1) }}</div>
            </div>
            <div class="item">
              <div class="text">完成人：</div>
              <div class="desc">{{ orderInfo.stockUserName }}</div>
            </div>
            <div class="item">
              <div class="text">完成时间：</div>
              <div class="desc">{{ $filters.timeFormat(orderInfo.stockTime, 1) }}</div>
            </div>
            <div class="item item1"
                 v-if="orderInfo.inventoryType != inventoryType.allStock && orderInfo.inventoryType != inventoryType.product">
              <div class="text">
                {{ getInventoryTypeName(orderInfo.inventoryType) }}：
              </div>
              <div class="desc">
                <span class="tags" v-for="item in orderInfo.inventoryValueNames" :key="item">{{ item }}</span>
              </div>
            </div>
            <div class="item item1"
                 v-else>
              <div class="text">
                盘点方式：
              </div>
              <div class="desc">
                <div class="text">
                  {{ getInventoryTypeName(orderInfo.inventoryType) }}
                </div>
              </div>
            </div>
            <div class="item item2" v-if="orderInfo.inOrderSn || orderInfo. outOrderSn">
              <div class="order-info" v-if="orderInfo.inOrderSn">
                <span class="text">盘点入库：</span>
                <span class="desc c_blue cur_p " @click="checkGoods(inventoryOrderType.in)">{{
                    orderInfo.inOrderSn
                  }}</span>
              </div>
              <div class="order-info" v-if="orderInfo. outOrderSn">
                <span class="text">盘点出库：</span>
                <span class="desc c_blue cur_p "
                      @click="checkGoods(inventoryOrderType.out)">{{ orderInfo.outOrderSn }}</span>
              </div>
            </div>
          </div>
          <div class="zh_title mt32">产品信息</div>
          <div class="zh_introduce status5">
            <div class="item">
              <div class="text">账面数量：</div>
              <div class="desc">{{ orderInfo.stockTotal }}</div>
            </div>
            <div class="item">
              <div class="text">实盘数量：</div>
              <div class="desc">{{ orderInfo.searchTotal }}</div>
            </div>
            <div class="item">
              <div class="text">差异数量：</div>
              <div class="desc">{{ orderInfo.diffTotal }}</div>
            </div>
          </div>
          <div class="tabList">
            <el-table
                :data="tableData"
                style="width: 100%"
                height="100%"
                class="zh-no-table-border"
                :row-class-name="tableRowClassName"
                :cell-style="{ border: 'none' }"
                :header-row-style="{ border: 'none' }"
            >
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              />
              <el-table-column
                  align="center"
                  class-name="table_text2"
                  show-overflow-tooltip
                  label="产品名称"
              >
                <template #default="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  label="简称"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.subName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  label="产品单位"
                  class-name="table_text2"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.unitName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  class-name="table_text2"
                  label="规格/型号"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.modelName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  class-name="table_text"
                  label="产品品牌"
                  show-overflow-tooltip
              >
                <template #default="scope">
                  {{ scope.row.brandName || "--" }}
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="table_text"
                               label="账面数量">
                <template #default="scope">
                  <div class="table_text2">
                    {{ scope.row.stockCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="table_text"
                               label="实盘数量">
                <template #default="scope">
                  <div class="table_text2">
                    {{ scope.row.searchCount }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="table_text"
                               label="差异数量">
                <template #default="scope">
                  {{ setDifferenceLabel(scope.row.diffCount) }}
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="table_text"
                               label="异常标签">
                <template #default="scope">
                  <div class="table_dp_item">
                    <span class="table_text2">{{ scope.row.exceptionLabels || '--' }}</span><span class="contBtn top1"
                                                                                                  @click="showRfid(scope.row)"
                                                                                                  v-if="scope.row.exceptionLabels > 0 && !noMore">查看</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" class-name="table_text"
                               label="盘点状态">
                <template #default="scope">
                  <div class="status-btn" :class="`status${scope.row.inventoryStatus}`">
                    {{ scope.row.inventoryStatusDesc }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mainPage">
            <el-pagination
                v-model:current-page="pageInfo.pageNumber"
                layout="total, prev, pager, next , jumper"
                :total="pageInfo.total"
                v-model:page-size="pageInfo.pageSize"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
      <OrderInfo ref="orderShow" @close="closeDetail"></OrderInfo>
      <err-label v-model="errShow" v-model:goodsInfo="goodsInfo"></err-label>
    </el-dialog>
  </div>
</template>
<script setup>
import ErrLabel from "./errLabel";
import {tableRowClassName} from "@/utils/tool.js";
import {productInfo} from "@/utils/api/product/product.js";
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {inventoryCompleteGoods, inventoryDetail} from '@/utils/api/inStock/inventory.js';
import {getInventoryTypeName, inventoryOrderType, inventoryType} from '../orderConfig'

const props = defineProps({
  noMore: {
    type: Boolean,
    default: false
  }
})
const {noMore} = toRefs(props)

const domHeight = ref('')
const { proxy } = getCurrentInstance();
const emit = defineEmits(["close"]);
const store = useStore();
const orderType = ref('');
const showOrderInfo = ref(false)
//获取配置文件
const errShow = ref(false);
const orderShow = ref(null);
const goodsInfo = reactive({
  orderSn: "",
  productId: "",
  modelId: "",
  productName: "",
  encode: [],
  unitName: "",
  modelName: "",
  checkPrice: "",
  brandName: "",
  checkCount: "",
  subName: "",
  price: "",
  img: "",
  count: "",
  inTotalPrice: "",
  outTotalPrice: "",
  stockCount: "",
  searchCount: "",
  diffCount: "",
  exceptionLabels: "",
  inventoryStatus: "",
  inventoryStatusDesc: "",
  inventoryNo: ""
});
const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
  total: 0,
});
//打开标签明细
const showRfid = async (e) => {
  reactiveAssign(goodsInfo, e);
  goodsInfo.inventoryNo = orderInfo.inventoryNo
  await getGoodsDetail(e)
};

// 获取产品详情
const getGoodsDetail = (e)=>{
  productInfo({
    productId:e.productId
  }).then((res)=>{
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(goodsInfo,res.data)
    errShow.value = true;
  })
}

const setDifferenceLabel = (e) => {
  return e > 0 ? '+' + e : e
}
const handleCurrentChange = (val)=>{
  pageInfo.pageNumber = val
  getGoodsList()
}

const orderInfo = reactive({
  orderSn: "",
  supplierName: "",
  productName: [],
  createTime: "",
  createUserName: "",
  remark: "",
  inOrderSn: "",
  outOrderSn: "",
  stockName: "",
  stockTime: "",
  updateUserName: "",
  inventoryNo: "",
  inventoryType: "",
  inventoryValues: "",
  inventoryValueNames: "",
  goodsType: "",
  stockId: "",
});

const goodsNum = computed(() => {
  // 账面数量
  let stockCount = 0;
  // 盘点数量
  let searchCount = 0;
  // 差异数量
  let diffCount = 0;
  tableData.value.forEach((item) => {
    stockCount += Number(item.stockCount) || 0;
    searchCount += Number(item.searchCount) || 0;
    diffCount += Number(item.diffCount) || 0;
  });
  return {
    stockCount,
    searchCount,
    diffCount,
  };
});

const tableData = ref([{}]);

const openDetail = () => {
    reactiveAssign(orderInfo, store.state.purchaseOrderInfo);
    getOrderInfo()
};
//获取盘点详情
function getOrderInfo(){
  inventoryDetail({
    inventoryNo: orderInfo.inventoryNo,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    reactiveAssign(orderInfo, res.data);
    if(res.data.inventoryValueNames){
      orderInfo.inventoryValueNames = res.data.inventoryValueNames.split(',')
    }
    getGoodsList();
  });
}

//获取产品列表
const getGoodsList = () => {
  inventoryCompleteGoods({
    inventoryNo: orderInfo.inventoryNo,
    pageNumber: pageInfo.pageNumber,
    pageSize: pageInfo.pageSize,
  }).then((res) => {
    if (res.code != 0) {
      proxy.$message.error(res.msg);
      return;
    }
    // if (res.data?.list?.length > 0) {
    //   res.data.list.forEach((item) => {
    //   });
    // }
    tableData.value = res.data?.list || [];
    pageInfo.total = res.data?.total || 0;
    setTimeout(() => {
      domHeight.value = document.querySelector('.dom-height').offsetHeight + 'px'
    }, 100)
  });
};
const close = () => {
  proxy.$emit("close");
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = [];
};

//查看订单详情
const checkGoods = (type) => {
  if (noMore.value) return
  let obj = {
    orderSn: type === inventoryOrderType.in ? orderInfo.inOrderSn : orderInfo.outOrderSn,
    orderType: type,
  };
  proxy.$store.commit("PURCHASE_ORDER_INFO", obj);
  orderShow.value.openDetails()
};

const closeDetail = () => {
  // orderShow.value.showDetail = false;
};

defineExpose({
  showOrderInfo
})
</script>

<script>
import { reactiveAssign } from "@/utils/tool.js";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";
import OrderInfo from '@views/order/components/orderDetailHistory.vue'
export default {
  name: "orderDetail",
  components: {
    OrderInfo,
    SelectByAbcModal,
    SelectGoodsFromList,
  },
};
</script>
<style lang="scss" scoped>
@import "../index.scss";

$height: v-bind(domHeight);
.inStockInfoContent {
  color: $height;
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;
    .zh_introduce{
      .status-text{
    font-size: 14px;
    &.status1{
      color: #FF910A;
    }
    &.status2{
      color: #3160F6;
    }
    &.status3{
      color: #AAAAB2;
    }
  }
      &.status3{
        height: auto;
        padding-bottom: 0;
      }
      &.status5{
       .item{
        @include textOverflow(20%);
        width: 20%;
       }
      }
      .item2{
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #E4E8ED;
        margin-bottom: 0;
        padding-top: 16px;
        padding-bottom: 14px;
        .desc{
          border-bottom: 1px solid $fsColor3;
          padding-bottom: 2px;
        }
      }
      .item1{
        @include textOverflow(100%);
        width: 100%;
        align-items: flex-start;

        > .text {
          height: 28px;
          line-height: 28px;
        }

        .desc {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: -8px;

          > .text {
            height: 28px;
            line-height: 28px;
            color: $fsColor;
          }

          .tags {
            border-radius: 8px;
            background-color: #fff;
            margin-right: 8px;
            margin-bottom: 8px;
            border: 1px solid #DCDEE0;
            width: max-content;
            padding: 0 8px;
          }
        }
      }
    }
    .tabList {
      $height: calc(630px - v-bind(domHeight));
      height: $height;
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;

      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        cursor: pointer;
        &.top1 {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }
      }
      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }
  }
  .mainPage{
   bottom: 0;
  }
}
</style>
