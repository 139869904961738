import {computed, ref} from 'vue';
import { labelRemarkEdit , labelRemarkDelete} from "@/utils/api/rfid.js";
import message from "@utils/tool/message";
import {labelRemarkMaxNum} from "@utils/config/ruleConfig";
import {ElMessageBox} from "element-plus";
export default function useLabelDesc() {
  const labelDesc = ref('');
  //编辑标签备注
  const editRemark = async (orderInfo) => {
      const {data, code, msg} = await labelRemarkEdit({
      labelId: orderInfo.labelId,
      labelDesc:labelDesc.value
      })
      if (code !== 0) {
      message.error(msg);
      return false;
      }
      message.success('备注修改成功')
      return true
  }
  //删除备注
    const deleteRemark = async (orderInfo) => {
      await  ElMessageBox.confirm("是否确认删除备注？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            customClass: "zh-message-box",
        })
        const {data, code, msg} = await labelRemarkDelete({
        labelId: orderInfo.labelId
        })
        if (code !== 0) {
        message.error(msg);
        return false;
        }
        message.success('备注删除成功')
        return true
    }

    const canSubmit = computed(() => {
        return labelDesc.value.length > 0 && labelDesc.value.length <= labelRemarkMaxNum
    })

    function resetLabelDesc(text=''){
        labelDesc.value = text || ''
    }
    return{
    labelDesc,
    editRemark,
    deleteRemark,
    canSubmit,
    resetLabelDesc
  }
}