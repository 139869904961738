<template>
  <!-- 产品详情弹窗 -->
  <div>
    <el-dialog
        v-bind="attrs"
        :title="'序列号：' + goodsInfo.serial + '  RFID标签追溯'"
        width="1020"
        class="goodInfo"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeDia"
        destroy-on-close
        align-center
        :model-value="modelValue"
        @open="oepnDia"
    >
      <div class="zh_content animate__animated" :class="[animateShow?'animate__zoomIn':'']">
        <div class="info" :class="goodsInfo.productId?'active':''" v-if="goodsInfo.productId">
          <div class="zh-title dp_f">
            <div class="title">当前关联商品</div>
            <div>
              <span class="right-text">标签状态：</span>
              <span :class="[
                    'zh-label-text',
                    `label-status-${goodsInfo.labelStatus}`,
                  ]">{{ goodsInfo.labelStatusDesc }}</span>
            </div>
          </div>
          <div class="zh-introduce-new">
            <div class="introduce-top introduce-content">
              <div class="single one">
                <div class="introduce-key">商品名称：</div>
                <show-more :text="goodsInfo.productName" class="introduce-value"></show-more>
              </div>
              <div class="single">
                <div class="introduce-key">产品品牌：</div>
                <show-more :text="goodsInfo.brandName || '--'" class="introduce-value"></show-more>
              </div>
              <div class="single">
                <div class="introduce-key">产品单位：</div>
                <show-more :text="getUnitNameLabel(goodsInfo)" class="introduce-value"></show-more>
              </div>
              <div class="single">
                <div class="introduce-key">规格/型号：</div>
                <show-more :text="goodsInfo.modelName" class="introduce-value"></show-more>
              </div>
            </div>
            <div class="introduce-bottom introduce-content">
              <div class="single one">
                <div class="introduce-key" v-if="showOrders(goodsInfo)">
                  {{ isInOrder(goodsInfo.operateType) ? "入库仓库:" : "出库仓库:" }}：
                </div>
                <div class="introduce-key" v-else>仓库：</div>
                <div class="introduce-value">{{ goodsInfo.stockName || '--' }}</div>
              </div>
              <div class="single one">
                <LabelDesc :labelInfo="goodsInfo" @change="changeDesc" ></LabelDesc>
              </div>
              <div class="single" v-if="goodsInfo.orderType && showOrders(goodsInfo)">
                <div class="introduce-key">订单信息：</div>
                <div class="introduce-value introduce-order" @click="clickOrderDetail(goodsInfo)">
                  {{ getOrderName(goodsInfo.orderType) }}{{
                    goodsInfo.orderSn
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="zh_empty info" style="height: 184px;" v-else></div>
        <div class="tabList" :style="{
          height: `${height  - 400}px`,
        }">
          <el-table
              :data="historyList"
              style="width: 100%"
              height="100%"
              class="zh-no-table-border"
              :row-class-name="tableRowClassName"
              :cell-style="{ border: 'none' }"
              :header-row-style="{ border: 'none' }"
              :span-method="arraySpanMethod"
          >
            <el-table-column align="center" width="200" label="变更时间">
              <template #default="scope">
                <template v-if="!scope.row.historyText">
                  <div>{{ $filters.timeFormat(scope.row.updateTime, 1) }}</div>
                </template>
                <template v-else>
                  <div class="zh-table-title">{{ scope.row.historyText }}</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="100"
                label="操作人"
                show-overflow-tooltip
            >
              <template #default="scope"
              >{{ scope.row.updateUserName }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="类型">
              <template #default="scope">
                <div class="dp_f" style="justify-content: center">
                  <div
                      :class="[
                    'zh-label-text',
                    `${isInOrder(scope.row.orderType)  ? 'in-stock' : 'out-stock'}`,
                    `order-type-${scope.row.orderType}`,
                  ]"
                  >
                    {{ scope.row.operateTypeDesc }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="描述">
              <template #default="scope">
                <div class="introduce" v-if="showOrders(scope.row)">
                  <div class="t" v-if="getThirdNames(scope.row.orderType)">
                    {{ getThirdNames(scope.row.orderType) }}：【{{
                      scope.row.thirdName
                    }}】
                  </div>
                  <div
                      class="t"
                      v-if="
                    scope.row.labelStatusDesc && scope.row.labelStatusDesc !== ''
                  "
                  >
                    备注：{{ scope.row.labelStatusDesc }}
                  </div>
                  <div class="b" @click="clickOrderDetail(scope.row)">
                    订单信息<span class="textUl rfid-order"
                  >【{{ getOrderName(scope.row.orderType) }}：{{
                      scope.row.orderSn
                    }}】
                    <!--    库存剩余数量 -->
                    <!--    end-->
                    </span
                    >
                  </div>
                  <div class="t remark-num">
                    <div class="stock-name">
                      {{
                        isInOrder(scope.row.operateType) ? "入库仓库:" : "出库仓库:"
                      }}：【{{ scope.row.stockName }}】
                    </div>
                    <div class="remark-name" v-if="scope.row.remark?.saleCount">
                      （出库数量：<span class="count-span mr_10">{{ scope.row.remark?.saleCount }}</span>
                      剩余库存数量：{{ scope.row.remark?.residue }}）
                    </div>
                  </div>

                </div>
                <div class="introduce" v-else-if="showCheckScattered(scope.row)">
                  <div class="t">
                    盘点仓库：{{
                      scope.row.stockName
                    }}
                  </div>
                  <div
                      class="t"
                  >
                    盘点前：{{ scope.row.remark?.beforeScatteredCount}}
                  </div>
                  <div class="b">
                    盘点后：{{ scope.row.remark?.afterScatteredCount }}
                  </div>
                </div>
                <div class="introduce" @click="showNewLabel(scope.row)" v-else-if="
                          (scope.row.orderType === 15 ||
                            scope.row.orderType === 16) &&
                          scope.row?.remark.labelSerial
                        ">
                  <div class="t">{{
                      scope.row.orderType === 16 ? '原标签序列号' : '新标签序列号'
                    }}：{{
                      scope.row?.remark.labelSerial
                    }}
                  </div>
                  <div class="introduce active chang-bind"
                       v-if="scope.row.orderType === 16 && scope.row.remark?.bindGoods">
                    <div class="single">
                      <div class="l">商品名称：
                        <el-tooltip
                            :content="scope.row.remark?.bindGoods.productName"
                            placement="top"
                            effect="light"
                        >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.productName
                              }}</span>
                        </el-tooltip>
                      </div>
                      <div class="l">
                        产品品牌：
                        <el-tooltip
                            :content="scope.row.remark?.bindGoods.brandName || '--'"
                            placement="top"
                            effect="light"
                        >
                          <span class="zh-value ">{{ scope.row.remark?.bindGoods.brandName || '--' }}</span>
                        </el-tooltip>
                      </div>
                    </div>
                    <div class="single">
                      <div class="l">产品单位：
                        <el-tooltip
                            :content="scope.row.remark?.bindGoods.unitName"
                            placement="top"
                            effect="light"
                        >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.unitName
                              }}</span>
                        </el-tooltip>
                      </div>
                      <div class="l">
                        规格/型号：
                        <el-tooltip
                            :content="scope.row.remark?.bindGoods.modelName"
                            placement="top"
                            effect="light"
                        >
                          <span class="zh-value ">{{ scope.row.remark?.bindGoods.modelName }}</span>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="introduce active" @click="showNewLabel(scope.row)"
                     v-else-if="showGoods(scope.row) && scope.row.remark?.bindGoods">
                  <div class="single">
                    <div class="l">商品名称：
                      <el-tooltip
                          :content="scope.row.remark?.bindGoods.productName"
                          placement="top"
                          effect="light"
                      >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.productName
                              }}</span>
                      </el-tooltip>
                    </div>
                    <div class="l">
                      产品品牌：
                      <el-tooltip
                          :content="scope.row.remark?.bindGoods.brandName || '--'"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value ">{{ scope.row.remark?.bindGoods.brandName || '--' }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="single">
                    <div class="l">产品单位：
                      <el-tooltip
                          :content="scope.row.remark?.bindGoods.unitName"
                          placement="top"
                          effect="light"
                      >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.unitName
                              }}</span>
                      </el-tooltip>
                    </div>
                    <div class="l">
                      规格/型号：
                      <el-tooltip
                          :content="scope.row.remark?.bindGoods.modelName"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value ">{{ scope.row.remark?.bindGoods.modelName }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="introduce "
                     v-else-if="isChangeScattered(scope.row) && scope.row.remark">
                  <div class="single">
                    <div class="l">原始单位：
                      <el-tooltip
                          :content="scope.row.remark?.beforeUnit+getChangeUnitName(scope.row).beforeCountUnit"
                          placement="top"
                          effect="light"
                          raw-content
                      >
                        <span class="zh-value "
                              v-html="scope.row.remark?.beforeUnit+getChangeUnitName(scope.row).beforeCountUnit"></span>
                      </el-tooltip>
                    </div>
                    <div class="l">
                      转化后单位：
                      <el-tooltip
                          :content="scope.row.remark?.afterUnit+getChangeUnitName(scope.row).afterCountUnit"
                          placement="top"
                          effect="light"
                          raw-content
                      >
                        <span class="zh-value "
                              v-html="scope.row.remark?.afterUnit+getChangeUnitName(scope.row).afterCountUnit"></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="single">
                    <div class="l">转化数量：
                      <el-tooltip
                          :content="scope.row.remark?.beforeCount + scope.row.remark?.beforeUnit+'→' + scope.row.remark?.afterCount + scope.row.remark?.afterUnit"
                          placement="top"
                          effect="light"
                          raw-content
                      >
                        <span class="zh-value "
                              v-html="scope.row.remark?.beforeCount + scope.row.remark?.beforeUnit+'→' + scope.row.remark?.afterCount + scope.row.remark?.afterUnit"></span>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="introduce "
                     v-else-if="isScatteredOutPacking(scope.row) && scope.row.remark">
                  <div class="single">
                    <div class="l">原始数量：
                      <el-tooltip
                          :content="scope.row.remark?.beforeCount + ''"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value " v-html="scope.row.remark?.beforeCount"></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="single">
                    <div class="l">{{ isScatteredOutPacking(scope.row).text }}数量：
                      <el-tooltip
                          :content="scope.row.remark?.wayCount + ''"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value " v-html="scope.row.remark?.wayCount"></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="single">
                    <div class="l">{{ isScatteredOutPacking(scope.row).text }}后数量：
                      <el-tooltip
                          :content="scope.row.remark?.afterCount + ''"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value " v-html="scope.row.remark?.afterCount"></span>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="introduce "
                     v-else-if="showShelf(scope.row) && scope.row.remark">
                  <div class="single">
                    <div class="l">仓库名称：
                      <el-tooltip
                          :content="scope.row.stockName"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value " v-html="scope.row.stockName"></span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="single">
                    <div class="l">货架编号：
                      <el-tooltip
                          :content="scope.row.remark?.childShelfName"
                          placement="top"
                          effect="light"
                      >
                        <span class="zh-value " v-html="scope.row.remark?.childShelfName"></span>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="introduce" v-else>
                  <div class="t">--</div>
                  <div class="t" v-if="scope.row.remark?.desc">{{ scope.row.remark?.desc }}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 采购订单详情 -->
      <OrderInfoDetail ref="orderShow" noMore></OrderInfoDetail>
      <OrderInfoAllocate
          v-model="orderInfoAllocateShow"
          noMore
      ></OrderInfoAllocate>
    </el-dialog>
  </div>
</template>
<script setup>
import OrderInfoAllocate from "@/views/inbound/allocate/components/inStockInfo.vue";
import OrderInfoDetail from "@/views/order/components/orderDetailHistory.vue";
import {resetObj, tableRowClassName} from "@/utils/tool.js";
import {useStore} from "vuex";
import {defineEmits, defineProps, getCurrentInstance, ref, toRefs, useAttrs} from "vue";
import {cloneDeep} from "lodash";
import {isInOrder} from "@utils/config"
import ShowMore from "@components/common/showMore/index.vue";
import useRfidHistory from "@utils/hooks/useRfidHistory";
import useRfidOrderInfo from "@utils/hooks/useRfidOrderInfo";
import {useViewPort} from "@utils/tool/useVIewPort";
import {getUnitNameLabel} from '@/utils/tool/orderTools'
import LabelDesc from "@components/rfidList/labelDesc/index.vue";

const {height} = useViewPort()
const {
  orderInfo: goodsInfo,
  getRfidOrderInfo,
  getOrderName,
  getThirdNames,
  showGoods,
  isShowBindOrder,
  isShowBindGoods,
  isUnBind,
  isBindGoods,
  isInStock,
  isCanChange,
  showInStock,
  showOrders,
  showCheckScattered,
  showShelf,
  isChangeScattered,
  isScatteredOutPacking,
  getChangeUnitName,
} = useRfidOrderInfo()
const {historyList, getRfidHistory} = useRfidHistory();
const attrs = useAttrs()
const { proxy } = getCurrentInstance();
const props = defineProps({
  rfidInfo: {
    type: Object,
    default: () => {},
  },
  modelValue: {
    type: Boolean,
    default: false
  }
});
const animateShow = ref(false);
const orderInfo = ref({});
const emit = defineEmits(["handleClose", 'update:modelValue']);
const store = useStore();
const orderShow = ref(null);
const {rfidInfo, modelValue} = toRefs(props);
const orderInfoAllocateShow = ref(false);

const changeDesc = (e)=>{
  goodsInfo.labelDesc = e
}
//打开订单详情弹窗
const clickOrderDetail = async (row) => {
  store.commit("PURCHASE_ORDER_INFO", row);
  orderShow.value.openDetails()
}

const closeDia = () => {
  emit("handleClose", {});
  emit('update:modelValue', false)
};

const oepnDia = () => {
  resetObj(goodsInfo)
  orderInfo.value = cloneDeep(rfidInfo.value);
  getData();
};

const getData = ()=>{
  getRfidOrderInfo({
    serial: orderInfo.value.serial
  });
  getRfidHistory({
    serial: orderInfo.value.serial
  })
}

const arraySpanMethod =({
  row,
  column,
  rowIndex,
  columnIndex,
})=>{
  if (row.historyText) {
    if (columnIndex === 0) {
      return [1, 2]
    } else if (columnIndex === 1) {
      return [0, 0]
    }
  }
}

const showNewLabel =(e)=>{
  if (!e.remark?.labelSerial) {
    return
  }
  if (orderInfo.value.serial === e.remark?.labelSerial) {
    return
  }
  animateShow.value = true;
  resetObj(goodsInfo)
  orderInfo.value.serial = e.remark?.labelSerial;
  getData()
  setTimeout(()=>{
    animateShow.value = false;
  },500)
}
</script>
<style lang="scss" scoped>
.goodInfo {
  .info{
    max-height: 0;
    transition: 0.5s all linear;

    > .zh-title {
      line-height: 1;
      padding-left: 6px;
      border-left: 3px solid $fsColor3;
      font-size: 16px;
      color: $fsColor;

      .right-text {
        display: inline-block;
        color: $fsColor2;
        font-size: 14px;
      }

      .title {
        font-size: 16px;
        color: $fsColor;
      }
    }
    &.active{
      max-height: 500px;
    }
    &.zh_empty{
      max-height: 184px;
    }
  }

  .zh_content {
    padding: 16px;
    box-sizing: border-box;
    position: relative;
    .tabList {
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;
    :deep(.el-table__row){
        height: 96px;
      }
      .introduce {
        text-align: left;
        font-size: 14px;
        color: #aaaab2;
        padding: 6px 0;

        .l {

          .zh-value {
            color: $fsColor;
          }
        }
        &.active {
          &.chang-bind {
            .single {
              .l {
                color: #7C71F5 !important;
              }
            }
          }

          .single {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // &:not(:last-child) {
            //   //margin-bottom: 6px;
            // }

            .l {
              width: 50%;
              display: flex;
              align-items: center;
              color: $mainColor;

              .zh-value {
                display: inline-block;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

          }
        }
        .t,
        .b {
          line-height: 1.8;
          @include textOverflow(500px);
        }
        .b {
          padding-bottom: 1px;
        }
      }
      .name {
        color: #73737f;
      }
      .text2 {
        color: $fsColor;
      }
    }
  }
}
</style>
<style lang="scss" src="./rfidHistory.scss" scoped></style>