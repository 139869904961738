/*
 * @Author: wangxinlie 278019672@qq.com
 * @LastEditors: wangxinlie 278019672@qq.com
 * @FilePath: /aurora-carton-web/src/views/product/labelManagement/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const orderTypeName = {
  '1':{
    typeName:"采购订单"
  },
  '2':{
    typeName:"其他订单"
  },
  '3':{
    typeName:"销退订单"
  },
  '4':{
    typeName:"调拨入库"
  },
  '5':{
    typeName:"盘点订单"
  },
  '6':{
    typeName:"其他订单"
  },
  '7':{
    typeName:"销售订单"
  },
  '8':{
    typeName:"调拨出库"
  },
  '9':{
    typeName:"盘点订单"
  },
  '10':{
    typeName:"采退订单"
  },
  '11':{
    typeName:"报损订单"
  },
    '17': {
        typeName: "期初订单"
    },
}

export const inStockList = [2, 3, 4, 5, 6, 17, 20]

export const isBindGoodsList = [1,2,3,4,5,6]

export const showSupplierList = [10, 1]

export const showCustomerList = [7, 3]

export const isScattered = (orderInfo) => {
  return orderInfo.scattered && orderInfo.scatteredCount !== orderInfo.scatteredStock
}

export const getUnitName = (orderInfo) => {
  return isScattered(orderInfo) ? orderInfo.scatteredName + `<span class='gray'>（拆）</span>` : orderInfo.unitName
}