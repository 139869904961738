<template>
  <!-- 产品详情弹窗 -->
    <el-dialog
        v-model="showDetail"
        :title="orderTitleText"
        width="1400"
        class="goodInfo"
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @close="close"
    >
      <div class="zh_content">
        <div class="info">
          <div class="zh_title">基本信息</div>
          <div class="zh_introduce status3">
            <div class="item">
              <div class="text">订单编号：</div>
              <div class="desc">{{ orderInfo.orderSn }}</div>
            </div>
            <div class="item" v-if="showThirdName">
              <div class="text">{{ showThirdName }}：</div>
              <div class="desc">{{ orderInfo.thirdName }}</div>
            </div>
            <div class="item" v-else-if="orderInfo.transferNo">
              <div class="text">调拨订单：</div>
              <div class="desc c_blue cur_p" @click="openTransferNo">{{ orderInfo.transferNo }}</div>
            </div>
            <div class="item" v-else-if="orderInfo.inventoryNo">
              <div class="text">盘点订单 ：</div>
              <div class="desc c_blue cur_p" @click="openInventory">{{ orderInfo.inventoryNo }}</div>
            </div>
            <div class="item">
              <div class="text">订单类型：</div>
              <div
                  :class="[
                            'zh-label-text',
                            `${
                             isInOrder(orderInfo.orderType) ? 'in-stock' : 'out-stock'
                            }`,
                            `order-type-${orderInfo.orderType}`,
                          ]"
              >
                {{ orderInfo.orderTypeDesc }}
              </div>
            </div>
            <div class="item">
              <div class="text">{{ isOutOrIn ? '入库仓库' : '出库仓库' }}：</div>
              <div class="desc">{{ orderInfo.stockName }}</div>
            </div>
            <div class="item">
              <div class="text">操作人：</div>
              <div class="desc">{{ orderInfo.stockUserName }}</div>
            </div>
            <div class="item">
              <div class="text">{{ isOutOrIn ? '入库时间' : '出库时间' }}：</div>
              <div class="desc">{{ orderInfo.stockTime }}</div>
            </div>
          </div>
          <div class="zh_title mt32">产品信息</div>
          <div class="zh_introduce">
            <div class="item">
              <div class="text">产品数量：</div>
              <div class="desc">{{ goodsNum.count || 0 }}</div>
            </div>
            <div class="item">
              <div class="text">产品金额(元)：</div>
              <div class="desc">{{ goodsNum.totalPrice || 0 }}</div>
            </div>
          </div>
          <div class="tabList">
            <el-table :data="tableData" class="zh-no-table-border"
                      :row-class-name="tableRowClassNameNew"
                      :cell-style="{border:'none'}"
                      :span-method="objectSpanMethod([1,2,3,4])"
                      :header-row-style="{border:'none'}" style="width: 100%" height="96%">
              <el-table-column
                  type="index"
                  width="100"
                  label="序号"
                  align="center"
                  fixed
              />
              <el-table-column align="center" class-name="table_text2" show-overflow-tooltip>
                <template #header>
                  <div>产品名称</div>
                </template>
                <template #default="scope">
                  {{ scope.row.productName }}
                </template>
              </el-table-column>
              <el-table-column width="100" align="center" label="简称" class-name="table_text2" show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.subName || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="130" align="center" label="产品品牌" class-name="table_text"
                               show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.brandName || '--' }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="规格/型号" class-name="table_text2"
                               show-overflow-tooltip>
                <template #default="scope">
                  {{ scope.row.modelName || '--' }}
                </template>
              </el-table-column>
              <el-table-column width="150" align="center" label="产品单位" class-name="table_text2"
                               show-overflow-tooltip>
                <template #default="scope">
                  <div v-html=" getUnitName(scope.row) "></div>
                </template>
              </el-table-column>

              <el-table-column width="100" align="center" v-if="showPriceOrder">
                <template #header>
                  <div>单价(元)</div>
                </template>
                <template #default="scope">
                  <div class="table_text2">
                    {{ scope.row.price }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="210" align="center">
                <template #header>
                  <div>{{ isOutOrIn ? '入库数量' : '出库数量' }}</div>
                </template>
                <template #default="scope">
                  <div class="table_dp_item">
                    <span>{{ scope.row.realityCount || 0 }}</span>
                    <span class="contBtn"
                          @click="showRfid(scope.row)"
                          v-if="scope.row.realityCount > 0 && !noMore && !scope.row.scattered">查看明细</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="200" align="center">
                <template #header>
                  <div>{{ isOutOrIn ? '入库金额(元)' : '出库金额(元)' }}</div>
                </template>
                <template #default="scope">
                  {{ scope.row.realityTotalPrice }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="remark">
            <div class="title">备注</div>
            <div class="remark_text">
              {{ orderInfo.remark }}
            </div>
            <div class="btnBox">
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <RfidList v-model="rfidShow" v-model:goodsInfo="goodsInfo"></RfidList>
    <inventory-record-detail ref="inventoryDetail" @close="closeInventory" no-more></inventory-record-detail>
    <transfer-order-detail ref="transferDetail" @close="closeTransfer" no-more></transfer-order-detail>
</template>
<script setup>
import {objectSpanMethod, tableRowClassNameNew} from '@/utils/tool.js';
import {computed, getCurrentInstance, ref, toRefs} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {getConfig} from '../uitl'
import {inStockList, orderBelong, orderStatus, showCheck, showCustomer, showPrice, showSupplier} from '../orderConfig'
import InventoryRecordDetail from "@views/inStock/inventoryRecord/components/inStockInfo.vue";
import TransferOrderDetail from "@views/inbound/allocateV2/components/orderDetailHistory.vue";
import {setOrderDetail} from "@utils/tool/order.js"
import {isInOrder} from "@utils/config";
import useRfid from "@utils/tool/product/useRfid";
import useOrder from "@utils/tool/order/useOrder";
import {getUnitName} from "@utils/tool/orderTools";
import QrCodeBtn from './qrCodeBtn.vue'

const showDetail = ref(false)
const props = defineProps({
  orderNum: {
    type: String,
    default: '',
  },
  noMore: {
    type: Boolean,
    default: false,
  },
});
const {orderNum, noMore} = toRefs(props);
const {proxy} = getCurrentInstance();
const emit = defineEmits(['close'])
const addShow = ref(false)
const route = useRoute();
const store = useStore();
const orderType = ref('');
const inventoryDetail = ref(null)
const transferDetail = ref(null)
const orderTitleText = ref('')
//打开盘点订单
const openInventory = () => {
  if (noMore.value) return
  setOrderDetail(orderInfo)
  inventoryDetail.value.showOrderInfo = true;
}
//打开调拨订单
const openTransferNo = () => {
  if (noMore.value) return
  let obj = Object.assign({type: orderInfo.orderType === orderStatus.transferIn ? 1 : 2}, orderInfo)
  setOrderDetail(obj)
  transferDetail.value.openDetails()
}
const closeInventory = () => {

}
const closeTransfer = () => {

}
//获取配置文件
const orderInfoText = ref({})
const inColumnsTableConfig = ref({})
const {goodsInfo, showRfidFunc} = useRfid()
const {orderInfo, setOrderInfo, tableData, getGoodsListDetFunc, getInStockGoodsList} = useOrder()
//是否展示第三方字段
const showThirdName = computed(() => {
  if (showSupplier.includes(orderInfo.orderType)) {
    return '供应商'
  }
  if (showCustomer.includes(orderInfo.orderType)) {
    return '客户'
  }
  return false
})

//判断出入库
const isOutOrIn = computed(() => {
  return inStockList.includes(orderInfo.orderType)
})
//展示盘点订单
const showCheckOrder = computed(() => {
  return showCheck.includes(orderInfo.orderType)
})

//展示单价
const showPriceOrder = computed(() => {
  return showPrice.includes(orderInfo.orderType)
})
//打开标签明细
const showRfid = async (e) => {
  showRfidFunc(e, () => {
    goodsInfo.orderSn = orderInfo.orderSn;
    goodsInfo.orderType = orderInfo.orderType;
    rfidShow.value = true;
  })
};
// 获取产品详情
const goodsNum = computed(() => {
  let count = 0;
  let totalPrice = 0
  tableData.value.forEach((item) => {
    count += Number(item.realityCount) || 0;
    totalPrice += Number(item.realityTotalPrice) || 0
  });
  return {
    count : Number.isInteger(count) ? count : count.toFixed(2),
    totalPrice: totalPrice.toFixed(2),
  }
});
const rfidShow = ref(false);
const openDetails = () => {
  setOrderInfo()
  orderType.value = orderInfo.orderType || route.params.orderType || orderNum.value || 0
  orderInfoText.value = getConfig(orderType.value).orderInfoText.value
  orderTitleText.value = orderBelong[orderType.value]
  inColumnsTableConfig.value = getConfig(orderType.value).inColumnsTableConfig.value
  getGoodsListDet()
  getGoodsList()
};
const getGoodsListDet = () => {
  getGoodsListDetFunc(orderType.value, (data) => {
    if (data.time) {
      data.time = proxy.$filters.timeFormat(data.time, 1)
    }
    if (data.stockTime) {
      data.stockTime = proxy.$filters.timeFormat(data.stockTime, 1)
    }
    showDetail.value = true
  })
}
//获取产品列表
const getGoodsList = () => {
  getInStockGoodsList(orderType.value)
}

const close = () => {
  proxy.$emit('close')
  proxy.$store.commit("PURCHASE_ORDER_INFO", {});
  tableData.value = []
}
const closeAdd = () => {
  addShow.value = false
  getGoodsList()
}

defineExpose({
  openDetails
})
</script>

<script>
import RfidList from "@/components/rfidList/indexByOrder.vue";
import {ArrowDown} from "@element-plus/icons-vue";
import SelectGoodsFromList from "@/components/selectGoodsFromList/index.vue";
import SelectByAbcModal from "@/components/selectByAbcModal/index.vue";

export default {
  components: {
    ArrowDown,
    RfidList,
    SelectByAbcModal,
    SelectGoodsFromList
  },
};
</script>
<style lang="scss" scoped>
.goodInfo {
  .zh_content {
    padding: 16px;
    height: 824px;
    box-sizing: border-box;
    position: relative;

    .zh_introduce {
      flex-wrap: wrap;

      .item {
        width: 33%;
      }
    }

    .tabList {
      height: 430px;
      overflow-y: auto;
      @include overFlow();
      padding-top: 20px;
      padding-bottom: 16px;
      box-sizing: border-box;

      .name {
        color: #73737f;
      }

      .text2 {
        color: $fsColor;
      }

      .contBtn {
        font-size: 12px;
        color: $fsColor3;
        display: inline-block;
        line-height: 1;
        cursor: pointer;
        margin-left: 8px;
        position: relative;
        top: -1px;
      }

      .el-input__inner {
        background-color: #f9fbfb !important;
      }
    }

    .remark {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 178px;
      padding: 0 32px 12px;
      box-sizing: border-box;

      .title {
        line-height: 1;
        padding-left: 6px;
        border-left: 3px solid $fsColor3;
        font-size: 16px;
        color: $fsColor;
      }

      .remark_text {
        text-indent: 2em;
        color: #737480;
        font-size: 14px;
        line-height: 2;
        margin-top: 10px;
      }

      .input {
        margin-top: 16px;
      }

      .btnBox {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 32px;
        bottom: 12px;

        .info_btn {
          width: 112px;
        }

        .select {
          margin: 0 8px;
          width: 160px;
          height: 40px;

          :deep(.el-input) {
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}

:deep(.el-textarea__inner) {
  height: 76px;
  border-radius: 8px;
  background-color: #f9fbfb;
}
</style>
