import {ref} from "vue";
import {labelHistoryInfo} from "@/utils/api/rfid";
import message from "@utils/tool/message";

export default function useRfidHistory() {
    const historyList = ref([]);
    //获取rfid历史列表
    const getRfidHistory = async (params = {}) => {
        const {data, code, msg} = await labelHistoryInfo(params);
        if (code !== 0) {
            message.error(msg);
            return;
        }
        let list = data?.list || [];
        let list3 = []
        list.forEach((item, index) => {
            item.list.forEach((item2) => {
                item2.orderType = item2.operateType;
                if (item2.operateType === 12 || item2.operateType === 16) {
                    item2.activeHigh = true; //列表高亮显示
                }
                //处理remark
                if (item2.remark) {
                    item2.remark = JSON.parse(item2.remark)
                }
            })
            if (index !== 0) {
                list3.push({
                    historyText: `以下为原标签：${item.serial}历史轨迹`
                })
            }
            list3 = list3.concat(item.list)
        });
        historyList.value = list3;
    };

    return {
        historyList,
        getRfidHistory
    }
}