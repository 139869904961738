export const orderConfig = {
  2: {
    //调拨入库
    columns: [
      {
        title: "订单编号",
        fieldKey: "transferNo",
        width: '200',
        isShow: true,
        className: 'table_text'
      },
      {
        title: "调出仓库",
        fieldKey: "outStockName",
        width: 200,
        isShow: true
      },
      {
        title: "产品名称",
        fieldKey: "productName",
        width: 300,
        isShow: true
      },
      {
        title: "计划数量",
        fieldKey: "count",
        width: 120,
        isShow: true,
        sortable: true
      },
      {
        title: "出库数量/金额(元)",
        fieldKey: "realityTotalPrice",
        width: 300,
        isShow: true,
      },
      {
        title: "收货仓库",
        fieldKey: "inStockName",
        width: 200,
        isShow: true,
      },
      {
        title: "状态",
        fieldKey: "statusDesc",
        width: 200,
        isShow: true,
      },
      {
        title: "操作人",
        fieldKey: "userName",
        width: 200,
        isShow: true,
      },
      {
        title: "创建时间",
        fieldKey: "time",
        width: 200,
        isShow: true,
        sortable: true,
        className: 'table_text'
      },
      {
        title: "消息",
        fieldKey: "remark",
        width: 'auto',
        isShow: true,
      },
    ],
    //调拨记录
    inColumns: [
      {
        title: "订单编号",
        fieldKey: "transferNo",
        width: '200',
        isShow: true,
        className: 'table_text'
      },
      {
        title: "调出仓库",
        fieldKey: "outStockName",
        width: 200,
        isShow: true
      },
      {
        title: "产品名称",
        fieldKey: "productName",
        width: 300,
        isShow: true
      },
      {
        title: "计划数量",
        fieldKey: "count",
        width: 120,
        isShow: true,
        sortable: true
      },
      {
        title: "出库数量/金额(元)",
        fieldKey: "realityTotalPrice",
        width: 300,
        isShow: true,
      },
      {
        title: "入库数量/金额(元)",
        fieldKey: "inTotalPrice",
        width: 300,
        isShow: true,
        sortable: true
      },
      {
        title: "收货仓库",
        fieldKey: "inStockName",
        width: 200,
        isShow: true,
      },
      // {
      //   title:"状态",
      //   fieldKey:"orderStatusDesc",
      //   width:100,
      //   isShow:true,
      // },
      {
        title: "操作人",
        fieldKey: "userName",
        width: 200,
        isShow: true,
      },
      {
        title: "创建时间",
        fieldKey: "time",
        width: 200,
        isShow: true,
        sortable: true,
        className: 'table_text'
      },
      {
        title: "消息",
        fieldKey: "remark",
        width: 'auto',
        isShow: true,
      },
    ],
    tabText: '待入库',
    tabTextIn: '调拨记录',
    addBtnText: '调拨入库',
    searchModal: {
      typeName: 'supplier',
      placeholder: '供应商名称',
    },
    addSearchText: '供应商',
    addStockText: '入库仓库',
    orderInfoText: {
      orderSn: '采购单号',
      time: '采购时间',
      userName: '采购人',
      outTime: '入库时间',
      thirdName: '供应商',
      stockName: '入库仓库',
    },
    inColumnsTableConfig: {
      salesText: '入库数量/金额（元）'
    }
  },
  1: {
    //调拨出库
    columns: [
      {
        title: "订单编号",
        fieldKey: "transferNo",
        width: '200',
        isShow: true,
        className: 'table_text'
      },
      {
        title: "调出仓库",
        fieldKey: "outStockName",
        width: 200,
        isShow: true
      },
      {
        title: "产品名称",
        fieldKey: "productName",
        width: 300,
        isShow: true
      },
      {
        title: "计划数量",
        fieldKey: "count",
        width: 120,
        isShow: true,
        sortable: true
      },
      {
        title: "收货仓库",
        fieldKey: "inStockName",
        width: 200,
        isShow: true,
      },
      {
        title: "状态",
        fieldKey: "statusDesc",
        width: 200,
        isShow: true,
      },
      {
        title: "操作人",
        fieldKey: "userName",
        width: 200,
        isShow: true,
      },
      {
        title: "创建时间",
        fieldKey: "time",
        width: 200,
        isShow: true,
        sortable: true,
        className: 'table_text'
      },
      {
        title: "消息",
        fieldKey: "remark",
        width: 'auto',
        isShow: true,
      },
    ],
    //调拨记录
    inColumns: [
      {
        title: "订单编号",
        fieldKey: "transferNo",
        width: '200',
        isShow: true,
        className: 'table_text'
      },
      {
        title: "调出仓库",
        fieldKey: "outStockName",
        width: 200,
        isShow: true
      },
      {
        title: "产品名称",
        fieldKey: "productName",
        width: 300,
        isShow: true
      },
      {
        title: "计划数量",
        fieldKey: "count",
        width: 120,
        isShow: true,
        sortable: true
      },
      {
        title: "出库数量/金额(元)",
        fieldKey: "realityTotalPrice",
        width: 300,
        isShow: true,
      },
      {
        title: "收货仓库",
        fieldKey: "inStockName",
        width: 200,
        isShow: true,
      },
      {
        title: "操作人",
        fieldKey: "userName",
        width: 200,
        isShow: true,
      },
      {
        title: "创建时间",
        fieldKey: "time",
        width: 200,
        isShow: true,
        sortable: true,
        className: 'table_text'
      },
      {
        title: "消息",
        fieldKey: "remark",
        width: 'auto',
        isShow: true,
      },
    ],
    tabText: '待出库',
    tabTextIn: '调拨记录',
    addBtnText: '调拨出库',
    searchModal: {
      typeName: 'supplier',
      placeholder: '供应商名称',
    },
    addSearchText: '供应商',
    addStockText: '入库仓库',
    orderInfoText: {
      orderSn: '采购单号',
      time: '采购时间',
      userName: '采购人',
      outTime: '入库时间',
      thirdName: '供应商',
      stockName: '入库仓库',
    },
    inColumnsTableConfig: {
      salesText: '入库数量/金额（元）'
    }
  },
}
export const orderName = {
    //调拨入库
    transferInStock: "2",
    //调拨出库
    transferOutStock: "1",
}
