import request from '../../http'
import {managerUrl} from '../../api'
//箱单关系

//产品箱单关系
export function packingProduct(data){
  return request({
    url:managerUrl + "/carton/product/packing/product",
    method:'post',
    data
  })
}
//箱单关系列表
export function packingList(data){
  return request({
    url:managerUrl + "/carton/product/packing/list",
    method:'post',
    data
  })
}
//箱单关系删除
export function packingDel(data){
  return request({
    url:managerUrl + "/carton/product/packing/del",
    method:'post',
    data
  })
}
//箱单关系编辑
export function packingEdit(data){
  return request({
    url:managerUrl + "/carton/product/packing/edit",
    method:'post',
    data
  })
}

//箱单关系新增
export function packingAdd(data){
  return request({
    url:managerUrl + "/carton/product/packing/add",
    method:'post',
    data
  })
}

//箱单关系产品搜索
export function packingProductSearch(data){
  return request({
    url:managerUrl + "/carton/product/info/search",
    method:'post',
    data,
    showLoading: false
  })
}

//箱单关系全部删除
export function packingAllDel(data){
  return request({
    url:managerUrl + "/carton/product/packing/del/top",
    method:'post',
    data
  })
}



