import request from '../http'
import {managerUrl} from '../api'
import { method } from 'lodash'
//标签绑定信息接口
export function labelBindInfo(data){
  return request({
    url:managerUrl + "/carton/product/label/bind/info",
    method:'post',
    data
  })
}

//标签历史信息接口
export function labelHistoryInfo(data){
  return request({
    url:managerUrl + "/carton/label/history",
    method:'post',
    data
  })
}

//标签绑定接口
export function labelBind(data){
  return request({
    url:managerUrl + "/carton/product/label/bind",
    method:'post',
    data
  })
}

//标签解除绑定
export function labelUnbind(data){
  return request({
    url:managerUrl + "/carton/product/label/unbind",
    method:'post',
    data
  })
}

//产品标签清空绑定
export function labelClearBind(data){
  return request({
    url:managerUrl + "/carton/product/label/unbind/all",
    method:'post',
    data
  })
}

//产品已绑定标签列表
export function labelBindList(data){
  return request({
    url:managerUrl + "/carton/product/label/product",
    method:'post',
    data
  })
}

//库存标签列表接口
export function stockLabelsLIst(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/labels",
    method:'post',
    data
  })
}

//标签当前信息接口
export function labelNowInfo(data){
  return request({
    url:managerUrl + "/carton/label/now/info",
    method:'post',
    data
  })
}

//编辑标签备注
export function labelRemarkEdit(data){
  return request({
    url:managerUrl + "/carton/label/change/desc",
    method:'post',
    data
  })
}

//标签删除备注
export function labelRemarkDelete(data){
    return request({
        url:managerUrl + "/carton/label/remove/desc",
        method:'post',
        data
    })
}

//订单标签列表
export function orderLabelsList(data){
  return request({
    url:managerUrl + "/carton/order/product/label/list",
    method:'post',
    data
  })
}

//标签状态检查
export function labelCheck(data){
  return request({
    url:managerUrl + "/carton/label/bind/check",
    method:'post',
    data
  })
}

//标签换绑
export function labelChangeBind(data){
  return request({
    url:managerUrl + "/carton/label/change/bind",
    method:'post',
    data
  })
}

//标签修改效期
export function labelChangeTime(data){
  return request({
    url:managerUrl + '/carton/label/change/time',
    method:'post',
    data
  })
}

//判断标签是否在库
export function orderCompleteCheck(data){
  return request({
    url:managerUrl + "/carton/product/stock/product/inStock/check",
    method:'post',
    data
  })
}

//解除标签标定
export function labelUnbindByLabel(data){
  return request({
    url:managerUrl + "/carton/label/unbind",
    method:'post',
    data
  })
}

//搜索Rfid列表
export function searchRfidListHot(data){
  return request({
    url:managerUrl + "/carton/label/query/serial",
    method:'post',
    data,
  })
}