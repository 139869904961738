import {computed, reactive} from "vue";
import {labelNowInfo } from "@/utils/api/rfid.js";
import message from "@utils/tool/message";
import useStock from "@utils/hooks/useStock";
import {
    inStockList,
    isBindGoodsList,
    orderTypeName,
    showCustomerList,
    showSupplierList
} from "@views/product/labelManagement/config";
import {labelStatus} from '@/utils/config'
import {orderOperateType} from '@/utils/tool/orderTools'

export default function useRfidOrderInfo() {
    const orderInfo = reactive({})
    const {stockList, getStockListData} = useStock();
    const getRfidOrderInfo = async (params = {}, callBack) => {
        const {data, code, msg} = await labelNowInfo(params);
        if (code !== 0) {
            message.error(msg);
            return;
        }
        if (data?.labelId) {
            Object.assign(orderInfo, data, params)
            orderInfo.orderType = data.operateType;
        } else {
            Object.assign(orderInfo, params)
            if (typeof callBack === 'function') {
                callBack(orderInfo)
            }
        }
    }

    const getOrderName = (orderType) => {
        if (orderType) {
            return orderTypeName[orderType].typeName;
        } else {
            return "";
        }
    };

    const getThirdNames = (orderType) => {
        if (showSupplierList.includes(orderType)) {
            return "供应商信息";
        } else if (showCustomerList.includes(orderType)) {
            return "客户信息";
        } else {
            return false;
        }
    };

    //判断是否在库
    const isInStock = computed(() => {
        return inStockList.includes(orderInfo.labelStatus);
    });

    //判断是否绑定产品
    const isBindGoods = computed(() => {
        return isBindGoodsList.includes(orderInfo.labelStatus);
    });

    //判断是否在库并且是否仓库是否
    const isCanChange = computed(() => {
        if (inStockList.includes(orderInfo.labelStatus)) {
            return stockList.value.findIndex(item => item.stockId === orderInfo.stockId) !== -1;
        } else {
            return true;
        }
    });

    //是否可以解绑
    const isUnBind = computed(() => {
        return (orderInfo.labelStatus === labelStatus.bind || orderInfo.labelStatus === labelStatus.out) && !orderInfo.stockId
    });

    //是否展示标签绑定产品
    const isShowBindGoods = computed(() => {
        return orderInfo.orderType !== orderOperateType.UNBIND && orderInfo.orderType
    })

    //是否展示标签绑定订单信息
    const isShowBindOrder = computed(() => {
        return orderInfo.operateType < orderOperateType.BIND || orderInfo.orderType === orderOperateType.CHANGE_BIND || orderInfo.operateType === orderOperateType.IN_BOX
    })

    //判断展示入库仓库还是出库仓库
    const showInStock = computed(() => {
        return orderInfo.operateType < orderOperateType.OTHER_OUT || orderInfo.operateType === orderOperateType.OPENING || orderInfo.operateType === orderOperateType.CHANGE_BIND
    })

    const showOrders = (orderInfo) => {
        return (orderInfo.operateType < orderOperateType.BIND || orderInfo.operateType === orderOperateType.OPENING) && (!showCheckScattered(orderInfo))
    }

    const showCheckScattered = (orderInfo) => {
        return (orderInfo.operateType === orderOperateType.CHECK_IN || orderInfo.operateType === orderOperateType.CHECK_OUT) && !orderInfo.orderSn
    }

    const showGoods = (orderInfo) => {
        return orderInfo.operateType === orderOperateType.BIND
    }

    const getChangeUnitName = (orderInfo) => {
        return {
            afterCountUnit: +orderInfo.orderType === orderOperateType.SCATTERED ? `<span class='scattered-scattered--text'>（零）</span>` : `<span class='scattered-monolithic--text'>（整）</span>`,
            beforeCountUnit: +orderInfo.orderType === orderOperateType.SCATTERED_BOX ? `<span class='scattered-scattered--text'>（零）</span>` : `<span class='scattered-monolithic--text'>（整）</span>`,
        }
    }

    function isScatteredOutPacking(orderInfo) {
        if (orderInfo.orderType !== orderOperateType.SCATTERED_OUT && orderInfo.orderType !== orderOperateType.SCATTERED_IN) {
            return false
        }
        return {
            text: `拆零${orderInfo.orderType === orderOperateType.SCATTERED_OUT ? '出' : '入'}箱`
        }
    }

    function isChangeScattered(orderInfo) {
        return orderInfo.orderType === orderOperateType.SCATTERED || orderInfo.orderType === orderOperateType.SCATTERED_BOX
    }

    //是否展示订单相关
    function showOrderName(orderInfo) {
        return orderInfo.operateType !== orderOperateType.CHANGE_BIND && orderInfo.operateType !== orderOperateType.IN_BOX
    }

    function showShelf(orderInfo) {
        if (orderInfo.operateType === orderOperateType.SHELF) {
            return '上架'
        } else if (orderInfo.operateType === orderOperateType.UN_SHELF) {
            return '下架'
        } else {
            return null
        }
    }



    return {
        isChangeScattered,
        isScatteredOutPacking,
        getChangeUnitName,
        orderInfo,
        getRfidOrderInfo,
        getOrderName,
        getThirdNames,
        isInStock,
        isCanChange,
        isUnBind,
        isBindGoods,
        isShowBindGoods,
        isShowBindOrder,
        showInStock,
        showOrders,
        showGoods,
        showCheckScattered,
        getStockListData,
        showOrderName,
        showShelf
    }
}

