export const orderConfig = {
    1: {
        //采购入库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        addBtnText: '新增采购',
        orderText: '采购入库',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '采购单号',
            time: '采购时间',
            userName: '采购人',
            outTime: '入库时间',
            thirdName: '供应商',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '入库数量/金额（元）'
        }
    },
    2: {
        //其他入库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        addBtnText: '新增采购',
        orderText: '其他入库',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '其他单号',
            time: '入库时间',
            userName: '操作人',
            outTime: '入库时间',
            thirdName: '供应商',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '入库数量/金额（元）'
        }
    },
    17: {
        //期初入库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "采购时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        addBtnText: '新增采购',
        orderText: '期初入库',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '其他单号',
            time: '入库时间',
            userName: '操作人',
            outTime: '入库时间',
            thirdName: '供应商',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '入库数量/金额（元）'
        }
    },
    3: {
        //销退入库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        orderText: '销退入库',
        addBtnText: '新增销退',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '销退单号',
            time: '创建时间',
            userName: '操作人',
            outTime: '入库时间',
            thirdName: '客户名称',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '销退数量/销退金额（元）'
        }
    },
    5: {
        //盘点入库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        orderText: '盘点入库',
        addBtnText: '新增销退',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '盘点单号',
            time: '创建时间',
            userName: '操作人',
            outTime: '入库时间',
            thirdName: '客户名称',
            stockName: '盘点仓库',
        },
        inColumnsTableConfig: {
            salesText: '销退数量/销退金额（元）'
        }
    },
    6: {
        //其他出库
        columns: [
            {
                title: "销退单号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "采购单号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        orderText: '其他出库',
        addBtnText: '新增销退',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '出库仓库',
        orderInfoText: {
            orderSn: '出库单号',
            time: '创建时间',
            userName: '操作人',
            outTime: '出库时间',
            thirdName: '客户名称',
            stockName: '出库仓库',
        },
        inColumnsTableConfig: {
            salesText: '销退数量/销退金额（元）'
        }
    },
    7: {
        //销售出库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "出库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/出库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/出库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "出库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "出库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待出库',
        orderText: '销售出库',
        tabTextIn: '已出库',
        addBtnText: '新增销售',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '出库仓库',
        orderInfoText: {
            orderSn: '销售单号',
            time: '创建时间',
            outTime: '出库时间',
            userName: '操作人',
            thirdName: '客户名称',
            stockName: '出库仓库',
        },
        inColumnsTableConfig: {
            salesText: '销售数量/销售金额（元）'
        }
    },
    9: {
        //盘点出库
        columns: [
            {
                title: "销退单号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "采购单号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        tabTextIn: '已入库',
        orderText: '盘点出库',
        addBtnText: '新增销退',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '出库仓库',
        orderInfoText: {
            orderSn: '盘点单号',
            time: '创建时间',
            userName: '操作人',
            outTime: '出库时间',
            thirdName: '客户名称',
            stockName: '盘点仓库',
        },
        inColumnsTableConfig: {
            salesText: '销退数量/销退金额（元）'
        }
    },
    10: {
        //采退出库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商 ",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "出库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "供应商",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/出库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/出库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "出库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "出库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待出库',
        tabTextIn: '已出库',
        orderText: '采退出库',
        addBtnText: '新增采退',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '出库仓库',
        orderInfoText: {
            orderSn: '采退单号',
            time: '创建时间',
            outTime: '出库时间',
            userName: '操作人',
            thirdName: '供应商',
            stockName: '出库仓库',
        },
        inColumnsTableConfig: {
            salesText: '出库数量/出库金额（元）'
        }
    },
    11: {
        //报损出库
        columns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        inColumns: [
            {
                title: "订单编号",
                fieldKey: "orderSn",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "客户名称",
                fieldKey: "thirdName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量/入库数量",
                fieldKey: "count",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "计划金额/入库金额(元)",
                fieldKey: "totalPrice",
                width: 200,
                isShow: true,
                sortable: true
            },
            {
                title: "入库仓库",
                fieldKey: "stockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "入库时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "备注",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
                className: 'table_text'
            },
        ],
        tabText: '待入库',
        orderText: '报损出库',
        tabTextIn: '已入库',
        addBtnText: '新增销退',
        searchModal: {
            typeName: 'punter',
            placeholder: '客户名称',
        },
        addSearchText: '客户名称',
        addStockText: '出库仓库',
        orderInfoText: {
            orderSn: '出库单号',
            time: '创建时间',
            userName: '操作人',
            outTime: '出库时间',
            thirdName: '客户名称',
            stockName: '出库仓库',
        },
        inColumnsTableConfig: {
            salesText: '销退数量/销退金额（元）'
        }
    },
    4: {
        //调拨入库
        columns: [
            {
                title: "调拨单号",
                fieldKey: "transferNo",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "调出仓库",
                fieldKey: "outStockName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "出库数量/金额(元)",
                fieldKey: "realityTotalPrice",
                width: 300,
                isShow: true,
            },
            {
                title: "收货仓库",
                fieldKey: "inStockName",
                width: 200,
                isShow: true,
            },
            {
                title: "状态",
                fieldKey: "statusDesc",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "消息",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
            },
        ],
        //调拨记录
        inColumns: [
            {
                title: "调拨单号",
                fieldKey: "transferNo",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "调出仓库",
                fieldKey: "outStockName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "出库数量/金额(元)",
                fieldKey: "realityTotalPrice",
                width: 300,
                isShow: true,
            },
            {
                title: "入库数量/金额(元)",
                fieldKey: "inTotalPrice",
                width: 300,
                isShow: true,
                sortable: true
            },
            {
                title: "收货仓库",
                fieldKey: "inStockName",
                width: 200,
                isShow: true,
            },
            // {
            //   title:"状态",
            //   fieldKey:"orderStatusDesc",
            //   width:100,
            //   isShow:true,
            // },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "消息",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
            },
        ],
        tabText: '待入库',
        orderText: '调拨入库',
        tabTextIn: '调拨记录',
        addBtnText: '调拨入库',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '采购单号',
            time: '采购时间',
            userName: '采购人',
            outTime: '入库时间',
            thirdName: '供应商',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '入库数量/金额（元）'
        }
    },
    8: {
        //调拨出库
        columns: [
            {
                title: "调拨单号",
                fieldKey: "transferNo",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "调出仓库",
                fieldKey: "outStockName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "收货仓库",
                fieldKey: "inStockName",
                width: 200,
                isShow: true,
            },
            {
                title: "状态",
                fieldKey: "statusDesc",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "消息",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
            },
        ],
        //调拨记录
        inColumns: [
            {
                title: "调拨单号",
                fieldKey: "transferNo",
                width: '200',
                isShow: true,
                className: 'table_text'
            },
            {
                title: "调出仓库",
                fieldKey: "outStockName",
                width: 200,
                isShow: true
            },
            {
                title: "产品名称",
                fieldKey: "productName",
                width: 300,
                isShow: true
            },
            {
                title: "计划数量",
                fieldKey: "count",
                width: 120,
                isShow: true,
                sortable: true
            },
            {
                title: "出库数量/金额(元)",
                fieldKey: "realityTotalPrice",
                width: 300,
                isShow: true,
            },
            {
                title: "收货仓库",
                fieldKey: "inStockName",
                width: 200,
                isShow: true,
            },
            {
                title: "操作人",
                fieldKey: "userName",
                width: 200,
                isShow: true,
            },
            {
                title: "创建时间",
                fieldKey: "time",
                width: 200,
                isShow: true,
                sortable: true,
                className: 'table_text'
            },
            {
                title: "消息",
                fieldKey: "remark",
                width: 'auto',
                isShow: true,
            },
        ],
        tabText: '待出库',
        tabTextIn: '调拨记录',
        orderText: '调拨出库',
        addBtnText: '调拨出库',
        searchModal: {
            typeName: 'supplier',
            placeholder: '供应商名称',
        },
        addSearchText: '供应商',
        addStockText: '入库仓库',
        orderInfoText: {
            orderSn: '采购单号',
            time: '采购时间',
            userName: '采购人',
            outTime: '入库时间',
            thirdName: '供应商',
            stockName: '入库仓库',
        },
        inColumnsTableConfig: {
            salesText: '入库数量/金额（元）'
        }
    },
}

export const orderTypeName = {
    1: '采购入库',
    2: '其他入库',
    3: '销退入库',
    4: '调拨入库',
    5: '盘点入库',
    6: '其他出库',
    7: '销售出库',
    8: '调拨出库',
    9: '盘点出库',
    10: '采退出库',
    11: '报损出库',
}

export const orderBelong = {
    1: '采购订单',
    2: '其他订单',
    3: '销退订单',
    4: '调拨订单',
    5: '盘点订单',
    6: '其他订单',
    7: '销售订单',
    8: '调拨订单',
    9: '盘点订单',
    10: '采退订单',
    11: '报损订单',
    17: '期初订单',
}

export const inStockList = [
    1, 2, 3, 4, 5, 17, 20
]

//展示客户
export const showCustomer = [
    3, 7
]

//展示供应商
export const showSupplier = [
    1, 10
]
//盘点订单
export const showCheck = [
    5, 9
]

//展示单价的订单
export const showPrice = [
    1, 2, 3, 6, 7, 10
]

export const orderStatus = {
    //调拨入库
    transferIn: 4,
    //调拨出库
    transferOut: 8,
}