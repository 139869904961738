import {reactive, ref} from 'vue';
import { orderConfig} from './orderConfig';
import { cloneDeep } from 'lodash';
import { useRoute } from "vue-router";
export function getConfig(orderType){
  const titleList = ref([])
  const titleListForCheck = ref([])
  const titleListIn = ref([])
  const titleListForCheckIn = ref([])
  const addBtnText = ref('')
  const addSearchText = ref('')
  const addStockText = ref('')
  const searchModal = reactive({
    typeName: '',
    placeholder: '',
  })
  const orderInfoText = ref({})
  const inColumnsTableConfig = ref('')
  let obj = orderConfig[orderType]
  addBtnText.value = obj.addBtnText
  addSearchText.value = obj.addSearchText
  addStockText.value = obj.addStockText
  titleList.value = cloneDeep(obj.columns)
  titleListForCheck.value = cloneDeep(obj.columns)
  titleListIn.value = cloneDeep(obj.inColumns)
  titleListForCheckIn.value = cloneDeep(obj.inColumns)
  searchModal.typeName = obj.searchModal.typeName
  searchModal.placeholder = obj.searchModal.placeholder
  orderInfoText.value = cloneDeep(obj.orderInfoText)
  inColumnsTableConfig.value = cloneDeep(obj.inColumnsTableConfig)
  return{
    titleList,
    titleListForCheck,
    addBtnText,
    searchModal,
    addSearchText,
    orderInfoText,
    titleListIn,
    titleListForCheckIn,
    inColumnsTableConfig,
    addStockText
  }
}

export function getOrderType(){
  const route = useRoute();
  const orderType = ref('')
  orderType.value = route.params.orderType || 0
  return orderType
}

