import request from '../http'
import {managerUrl} from '../api'

//首页消息接口
export function indexMsg(data){
  return request({
    url:managerUrl + "/carton/message/order/index",
    method:'post',
    data
  })
}

//订单消息接口
export function orderMsg(data){
  return request({
    url:managerUrl + "/carton/message/order/list",
    method:'post',
    data,
    showLoading: false
  })
}

//发送消息接口
export function sendMsg(data){
  return request({
    url:managerUrl + "/carton/message/order/send",
    method:'post',
    data
  })
}