<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-08-11 16:17:57
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-17 10:14:41
 * @FilePath: /aurora-carton-web/src/views/inStock/inventoryRecord/components/errLabel.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <el-dialog
        v-bind="attrs"
      title="异常标签"
      width="920"
      class="err-label-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      align-center
        @open="openDetail"
    >
      <div class="zh_content" v-loading="loading">
        <div class="info">
          <div class="dp_f">
            <div class="zh_title">产品信息</div>
            <div class="status-btn" :class="`status${goodsInfo.inventoryStatus}`">{{
                goodsInfo.inventoryStatusDesc
              }}
            </div>
          </div>
          <div class="zh_goods_info">
            <div class="zh_goods_img">
              <img :src="goodsInfo.img" class="zh_pic" />
            </div>
            <div class="zh_goods">
              <div class="zh_goods_name">
                {{ goodsInfo.productName }} <span class="zh_line">/</span>
                <span class="zh_unit">{{ goodsInfo.unitName }} </span>
              </div>
              <div class="zh_goods_item dp_f">
                <div class="zh_goods_item_l">
                  <span class="zh_goods_title">简称：</span>
                  <span class="zh_goods_text">{{
                      goodsInfo.subName || "--"
                  }}</span>
                </div>
                <div class="zh_goods_item_l">
                  <span class="zh_goods_title">产品品牌：</span>
                  <span class="zh_goods_text">{{
                      goodsInfo.brandName || "--"
                    }}</span>
                </div>
              </div>
              <div class="zh_goods_item dp_f">
                <div class="zh_goods_item_l">
                  <span class="zh_goods_title">产品单位：</span>
                  <span class="zh_goods_text">{{
                      goodsInfo.unitName || "--"
                    }}</span>
                </div>
                <div class="zh_goods_item_l">
                  <span class="zh_goods_title">规格/型号：</span>
                  <span class="zh_goods_text">{{
                      goodsInfo.modelName || "--"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="order-info">
            <div class="content">
              <div class="item">
              <span  class="text">账面数量：</span>
                <span class="desc">{{ goodsInfo.stockCount }}</span>
            </div>
            <div class="item">
              <span class="text">盘点数量：</span>
              <span class="desc">{{ goodsInfo.searchCount }}</span>
            </div>
            <div class="item">
              <span class="text">差异数量：</span>
              <span class="desc">{{ goodsInfo.diffCount }}</span>
            </div>
            <div class="item">
              <span class="text">异常标签：</span>
              <span class="desc">{{ goodsInfo.exceptionLabels }}</span>
            </div>
            </div>
          </div>
          <div class="zh_title mt_20">标签明细</div>
          <div class="tabList">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="100%"
              class="zh-no-table-border"
              :row-class-name="tableRowClassName"
              :cell-style="{ border: 'none' }"
              :header-row-style="{ border: 'none' }"
            >
              <el-table-column align="center" label="序号" width="100">
                <template #default="scope">
                  <div class="table_text">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="账面标签">
                <template #default="scope">
                  <div class="c_blue cur_p" @click="showRfid(scope.row,errLabelStatus.stockLabel)">
                    {{ scope.row.type === errLabelStatus.stockLabel ? scope.row.serial : '--' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="实盘标签" width="200">
                <template #default="scope">
                  <div class="c_blue cur_p" @click="showRfid(scope.row,errLabelStatus.checkLabel)">
                    {{ scope.row.type === errLabelStatus.checkLabel ? scope.row.serial : '--' }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mainPage">
            <el-pagination
                v-model:current-page="pageInfo.pageNumber"
                layout="total, prev, pager, next , jumper"
                :total="pageInfo.total"
                v-model:page-size="pageInfo.pageSize"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </el-dialog>
    <RfidHistory v-model="historyShow" v-model:rfidInfo="rfidInfo" @handleClose="closeRfid"></RfidHistory>
</template>
<script setup>
import {toRefs, ref, getCurrentInstance, reactive, useAttrs} from "vue";
import { tableRowClassName } from "@/utils/tool.js";
import RfidHistory from "@components/rfidList/rfidHistory.vue";
import {inventoryAbnormalLabel} from '@/utils/api/inStock/inventory.js';
import MoreList from "@components/moreList/moreList.vue";
const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
});
const loading = ref(false);
const attrs = useAttrs();
const {goodsInfo} = toRefs(props);
const {proxy} = getCurrentInstance();
const rfidInfo = reactive({
  labelId:'',
  rfid:"",
  serial:'',
  orderSn:goodsInfo.value.orderSn,
  productId:goodsInfo.value.productId,
  modelId:goodsInfo.value.modelId,
})
const pageInfo = reactive({
  pageNumber: 1,
  pageSize: 10,
  total: 0,
});
const historyShow = ref(false)
const tableData = ref([]);
const errLabelStatus = reactive({
  //账面标签
  stockLabel: 1,
  //实盘标签
  checkLabel: 2,
})
const showRfid = (row,type)=>{
  if (row.type !== type) return
  rfidInfo.labelId = row.labelId
  rfidInfo.rfid = row.rfid
  rfidInfo.serial = row.serial
  historyShow.value = true
}
const closeRfid =()=>{
  historyShow.value = false
}

//获取列表
async function getList() {
  loading.value = true;
  const res = await inventoryAbnormalLabel({
    inventoryNo: goodsInfo.value.inventoryNo,
    productId: goodsInfo.value.productId,
    modelId: goodsInfo.value.modelId,
    pageNumber: pageInfo.pageNumber,
    pageSize: pageInfo.pageSize,
  })
  loading.value = false;
  if (res.code !== 0) {
    proxy.$message.error(res.msg)
    return
  }
  tableData.value = res.data?.list || []
  pageInfo.total = res.data?.total || 0
}

const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val
  getList()
}

const openDetail = async () => {
  await getList()
};

</script>

<style lang="scss" scoped src="../index.scss"></style>

<style lang="scss" scoped>
.err-label-dialog {
  .info {
    padding-bottom: 70px;
    position: relative;
  }
  .order-info{
    padding:0 16px;
    border-radius: 0 0 8px 8px ;
    background-color: #fafbfc;
    >.content{
      padding: 16px 0 ;
      border-top: 1px solid #E4E8ED;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item{
        .text{
          color: #737480;
        }
        .desc{
          color: #161829;
        }
      }
    }
  }
  .zh_goods_info {
    display: flex;
    border-radius: 8px 8px 0 0;
    background-color: #fafbfc;
    padding: 24px;
    margin-top: 16px;
    justify-content: space-between;
    .zh_goods_img {
      width: 72px;
      height: 72px;
      border-radius: 8px;
      overflow: hidden;
      .zh_pic {
        width: 100%;
        height: 100%;
      }
    }
    .zh_goods {
      width: 746px;
      color: $fsColor;
      .zh_goods_name {
        margin: 10px 0 25px 0;
        line-height: 1;
        font-size: 22px;
        .zh_line {
          display: inline-block;
          margin: 0 6px;
          font-size: 18px;
        }
        .zh_unit {
          font-size: 18px;
        }
      }
      .zh_goods_item {
        margin-bottom: 24px;
        &:last-of-type{
          margin-bottom: 0;
        }
        .zh_goods_item_l {
          display: flex;
          align-items: center;
          flex: 1;
          flex-wrap: nowrap;
          line-height: 1.3;
          > span {
            display: inline-block;
          }
          .zh_goods_title {
            color: #737480;
          }
          .zh_goods_text {
            @include textOverflow(200px);
            .unit {
              display: inline-block;
              margin-right: 2px;
            }
          }
        }
      }
    }
  }
  .tabList{
    margin-top: 16px;
    height: 500px;
  }
}
</style>
