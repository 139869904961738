export default {
    shelfName: '请输入货架名称（如：A货架）',
    shelfStock: '请选择仓库',
    status: '请选择状态',
    shelfColumn: '请输入列数',
    shelfRow: '请输入行数',
    shelfRemark: '备注信息',
    childShelfId: '请输入货架编号',
    labelDesc: '请输入备注，最多20位',
    otherOrderType: '请选择其他订单类型',
    otherOrderTypesAdd: '类型名称，最多6位',
    orderTypeSelect:'请选择出入库类型',
    outStockType: '请选择出库类型',
    inStockType: '请选择入库类型',
}